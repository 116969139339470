import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { weekStoreAction, authAction } from "redux/modules";
import { imageShowUrl } from "../../../config/api.config";
import showMessage from "hooks/showMessage";
import showErrMessage from "hooks/showErrMessage";

import {
  IoMenuOutline,
  IoCloseOutline,
  IoChevronDown,
  IoArrowDown,
  IoArrowForward,
  IoCheckmarkCircleSharp
} from "react-icons/io5";
import { FaInfoCircle } from "react-icons/fa";

const WeekCompetition = props => {
  const weekStoreToBeVotedList = useSelector(
    store => store.weekStore.weekStoreToBeVotedList
  );
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  var weekStoreToBeVotedContent = null;
  const selectedWeekStoreCandidate = useSelector(
    store => store.weekStore.selectedWeekStoreCandidate
  );
  const [myVotes, setMyVotes] = useState(null);
  const [votedList, setVotedList] = useState(null);

  useEffect(() => {
    dispatch(weekStoreAction.getWeekStoreToBeVotedList(employeeFirm.firm.id));
    return () => {};
  }, []);

  useEffect(() => {
    if (weekStoreToBeVotedList) {
      dispatch(
        weekStoreAction.getMyVotesByCompetition(
          employeeFirm.id,
          weekStoreToBeVotedList[0].hdCompetitionId
        )
      )
        .then(e => {
          setMyVotes(e.data);
        })
        .catch(err => {
          showErrMessage("Verilmiş oylar çekilemedi");
        });
    }

    return () => {};
  }, [weekStoreToBeVotedList]);

  useEffect(() => {
    if (myVotes) {
      let list = [];
      weekStoreToBeVotedList.forEach(element => {
        myVotes.forEach(vote => {
          if (element.id === vote) {
            element.voted = true;
          }
        });
      });

      list = [...weekStoreToBeVotedList];

      setVotedList(weekStoreToBeVotedList);
    }
    return () => {};
  }, [myVotes, weekStoreToBeVotedList]);

  const handleVote = (hdCompetitionId, id) => {
    dispatch(
      weekStoreAction.voteVendor(
        employeeFirm.firm.id,
        employeeFirm.id,
        hdCompetitionId,
        id
      )
    )
      .then(e => {
        if (myVotes) {
          if (myVotes.length > 0) {
            showMessage("Oy verdiniz!");
          } else {
            showMessage("Oy verdiniz ve 200 puan kazandınız!");
            dispatch(
              authAction.returnNewScoreOfEmployee(
                employeeFirm.firm.id,
                employeeFirm.employee.id
              )
            );
          }
        } else {
          showMessage("Oy verdiniz ve 200 puan kazandınız!");
          dispatch(
            authAction.returnNewScoreOfEmployee(
              employeeFirm.firm.id,
              employeeFirm.employee.id
            )
          );
        }
      })
      .catch(err => {
        showErrMessage(err.error.response.data.errors[0].message);
      });
  };

  // function showSliderWithSelectedContent(competitionId, slideNumber) {
  //   //Get votes of current user by competition
  //   dispatch(
  //     weekStoreAction.getMyVotesByCompetition(employeeFirm.id, competitionId)
  //   )
  //     .then(e => {
  //       dispatch(weekStoreAction.setSliderContent(slideNumber));
  //     })
  //     .catch(err => {
  //       showErrMessage("Verilmiş oylar çekilemedi");
  //     });
  // }

  if (weekStoreToBeVotedList) {
    if (votedList && votedList.length > 0) {
      weekStoreToBeVotedContent = votedList.map(function(item, index) {
        return (
          <div
            key={index}
            className="overflow-hidden rounded-xl bg-white p-0 transition-all duration-300 hover:shadow-lg text-center"
          >
            <img
              style={{ height: "195px", width: "100%" }}
              data-id={index + 1}
              src={imageShowUrl + item.employeeImageDTO.imageUrl}
              className="mb-4 w-full"
              // onClick={() =>
              //   showSliderWithSelectedContent(item.hdCompetitionId, index + 1)
              // }
            />

            <div className="mb-3">
              <h3 className="mb-2 text-slate-700 font-semibold text-base">
                {item.employeeName} {item.employeeSurname}
              </h3>
              <p className="mb-3 text-gray2 text-xs min-h-[32px]">
                {item.vendorName}
                {item.addressDTO && "/ " + item.addressDTO.provinceName}
              </p>
              <p className="mb-3 text-gray2 text-xs">{item.votesReceived} Oy</p>
              {item.voted === true ? (
                <button
                  className="m-auto font-semibold flex items-center justify-center gap-2 rounded-full border-2 border-primary-500 bg-primary-500 md:px-10 px-4 py-2 text-white text-xs"
                  onClick={e => e.preventDefault()}
                >
                  Oy Verildi{" "}
                  <IoCheckmarkCircleSharp className="text-white h-5 w-5" />
                </button>
              ) : (
                <button
                  className="m-auto font-semibold flex items-center justify-center gap-2 rounded-full border-2 border-slate-700 bg-white md:px-10 px-4 py-2 text-slate-700 text-xs"
                  onClick={() => handleVote(item.hdCompetitionId, item.id)}
                >
                  Oy Ver <IoArrowForward className="text-slate-500 h-5 w-5" />
                </button>
              )}
            </div>
          </div>
        );
      });
    } else {
      weekStoreToBeVotedContent = weekStoreToBeVotedList.map(function(
        item,
        index
      ) {
        return (
          <div
            key={index}
            className="overflow-hidden rounded-xl bg-white p-0 transition-all duration-300 hover:shadow-lg text-center"
          >
            <img
              style={{ cursor: "pointer", height: "195px", width: "100%" }}
              data-id={index + 1}
              src={imageShowUrl + item.employeeImageDTO.imageUrl}
              className="mb-4 w-full"
              // onClick={() =>
              //   showSliderWithSelectedContent(item.hdCompetitionId, index + 1)
              // }
            />

            <div className="mb-3">
              <h3 className="mb-2 text-slate-700 font-semibold text-base">
                {item.employeeName} {item.employeeSurname}
              </h3>
              <p className="mb-3 text-gray2 text-xs min-h-[32px]">
                {item.vendorName} /{" "}
                {item.addressDTO && item.addressDTO.provinceName}
              </p>
              <p className="mb-3 text-gray2 text-xs">{item.votesReceived} Oy</p>
              <button
                className="m-auto font-semibold flex items-center justify-center gap-2 rounded-full border-2 border-slate-700 bg-white md:px-10 px-4 py-2 text-slate-700 text-xs"
                onClick={() => handleVote(item.hdCompetitionId, item.id)}
              >
                Oy Ver <IoArrowForward className="text-slate-500 h-5 w-5" />
              </button>
            </div>
          </div>
        );
      });
    }
  }

  return (
    <>
      {selectedWeekStoreCandidate ? null : (
        <>
          <h4 className="text-center font-semibold text-slate-700 mb-5">
            Haftanın Dükkanı Adayları
          </h4>

          <div
            className="mt-6 grid grid-cols-1 gap-2 md:grid-cols-3 lg:grid-cols-4 md:gap-3"
            style={{ maxWidth: "none" }}
          >
            {weekStoreToBeVotedContent}
          </div>
        </>
      )}
    </>
  );
};

export default WeekCompetition;
