import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import {
  orderAction,
  authAction,
  catalogAction,
  profileAction
} from "redux/modules";
import { useSelector, useDispatch } from "react-redux";
import { imageShowUrl } from "../../../config/api.config";
import Pagination from "react-js-pagination";
import { Link, useLocation } from "react-router-dom";
import removeErrMessage from "hooks/removeErrMessage";
import showErrMessage from "hooks/showErrMessage";
import qS from "query-string";

import UnhappyIcon from "assets/portal-resource/img/unhappy.svg";

import "./DynamicCatalogPage.scss";

import { IoCloseOutline, IoArrowForward } from "react-icons/io5";
import { FaInfoCircle, FaShoppingCart, FaHeart } from "react-icons/fa";

const DynamicCatalogPage = props => {
  const location = useLocation();

  const [showItem, setShowItem] = useState(false);
  //Modal değişkenleri
  const [modalShow, setModalShow] = React.useState(false);
  const [modalCombineShow, setModalCombineShow] = React.useState(false);
  const [tcknErr, setTcknErr] = React.useState(false);
  const [addressAlertModalShow, setAddressAlertModalShow] = React.useState(
    false
  );
  const [
    successfulOrderModalShow,
    setSuccessfulOrderModalShow
  ] = React.useState(false);

  const [
    insufficientScoreModalShow,
    setInsufficientScoreModalShow
  ] = React.useState(false);

  const sortingType = useSelector(store => store.catalog.sortingType);

  const [exceededOrderModal, setExceededOrderModal] = React.useState(false);

  const [exceededStockModal, setExceededStockModal] = React.useState(false);

  const [cartItem, setCartItem] = React.useState({
    id: null,
    active: true,
    description: null,
    icon: null,
    name: null,
    score: null,
    subCategoryId: null,
    productType: null,
    stock: null
  });

  const dispatch = useDispatch();
  const productList = useSelector(store => store.catalog.productList);
  const selectedCategory = useSelector(store => store.catalog.selectedCategory);
  const selectedSubCategory = useSelector(
    store => store.catalog.selectedSubCategory
  );
  const sortAsc = useSelector(store => store.catalog.sortAsc);
  const address = useSelector(store => store.order.address);
  const totalPages = useSelector(store => store.catalog.totalPages);

  const sortByTypeAsc = useSelector(store => store.catalog.sortByTypeAsc);
  const [activePage, setActivePage] = useState(1);
  const [orderNumber, setOrderNumber] = useState(false);
  const [orderNumberConfirmation, setOrderNumberConfirmation] = useState(false);

  const actives = useSelector(store => store.profile.actives);
  const isLoading = useSelector(store => store.catalog.isLoading);
  const selectedProduct = useSelector(store => store.catalog.selectedProduct);
  const similarProducts = useSelector(store => store.catalog.similarProducts);
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const employeeAddresses = useSelector(
    store => store.catalog.employeeAddresses
  );

  const catalogModalState = useSelector(
    store => store.catalog.catalogModalState
  );
  const catalogCartItemState = useSelector(
    store => store.catalog.catalogCartItemState
  );

  const queryString = () => {
    const parsed = qS.parse(window.location.search);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (window.location.search.indexOf("?category") > -1) {
        queryString();
      }

      if (employeeAddresses && employeeAddresses.length > 0) {
        employeeAddresses.map(item => {
          if (item.orderAddress === true) {
            dispatch(orderAction.setAddress(item.name));
          }
        });
      }

      // setActivePage(1);
    }

    return () => {
      isMounted = false;
    };
  }, [
    selectedSubCategory,
    selectedCategory,
    sortAsc,
    totalPages,
    sortByTypeAsc,
    employeeAddresses,
    selectedProduct,
    props.location,
    dispatch
  ]);

  useEffect(() => {
    dispatch(orderAction.getOrderNumber(employeeFirm.id)).then(res => {
      setOrderNumber(res);
    });

    return () => {};
  }, [employeeFirm]);

  useEffect(() => {
    if (location?.product) {
      setSelectedProduct(location?.product);
      setShowItem(true);
      document.body.style.overflow = "hidden";
      handleSimilarProducts(location?.product?.id);
    }

    return () => {};
  }, [location]);

  function handlePageChange(pageNumber) {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    setActivePage(pageNumber);

    if (selectedSubCategory !== "")
      if (sortByTypeAsc === "ALL")
        dispatch(
          catalogAction.getProductsBySubCatId(
            selectedSubCategory.id,
            sortAsc,
            0,
            pageNumber - 1,
            employeeFirm.id
          )
        );
      else
        dispatch(
          catalogAction.getProductsBySubCatId(
            selectedSubCategory.id,
            sortAsc,
            employeeFirm.score.score,
            pageNumber - 1,
            employeeFirm.id
          )
        );
    else {
      dispatch(
        catalogAction.getProductsBySubCatId(
          0,
          sortAsc,
          0,
          pageNumber - 1,
          employeeFirm.id
        )
      );
    }
  }
  function getAll(sorting) {
    dispatch(catalogAction.setSortingAsc("ASC"));
    dispatch(
      catalogAction.getProductsBySubCatId(0, sorting, 0, 0, employeeFirm.id)
    );
    dispatch(catalogAction.setCategoryName(""));
    dispatch(catalogAction.setSubCategoryName(""));
    dispatch(catalogAction.setSelectedProduct(null));
  }

  function getSortedProducts(sortProducts, sorting, score) {
    dispatch(catalogAction.setSortingAsc(sortProducts));
    dispatch(catalogAction.setSelectedProduct(null));

    if (selectedSubCategory !== "")
      dispatch(
        catalogAction.getProductsBySubCatId(
          selectedSubCategory.id,
          sorting,
          score,
          0,
          employeeFirm.id
        )
      );
    else
      dispatch(
        catalogAction.getProductsBySubCatId(
          0,
          sorting,
          score,
          0,
          employeeFirm.id
        )
      );
  }

  function getSortedByProductType(productType, sorting, score) {
    if (selectedSubCategory !== "") {
      dispatch(
        catalogAction.getProductsBySubCatId(
          selectedSubCategory.id,
          sorting,
          score,
          0,
          employeeFirm.id
        )
      );
    } else {
      if (sortByTypeAsc === "SCORE")
        dispatch(
          catalogAction.getProductsBySubCatId(0, sorting, 0, 0, employeeFirm.id)
        );
      else
        dispatch(
          catalogAction.getProductsBySubCatId(
            0,
            sorting,
            employeeFirm.score.score,
            0,
            employeeFirm.id
          )
        );
    }
    dispatch(catalogAction.setSortingAsc("ASC"));
    dispatch(catalogAction.setSortingByProductTypeAsc(productType));
    dispatch(catalogAction.setSelectedProduct(null));
    setActivePage(1);
  }

  function goToAdress(key, index2) {
    [0, 1, 2, 3, 4, 5, 6, 7].map(() => {
      actives.push(false);
    });

    actives.map((item, index) => {
      index === index2 ? (actives[index] = true) : (actives[index] = false);
    });

    dispatch(profileAction.setActives(actives));
    dispatch(profileAction.setProfileContent(key));
  }

  async function confirmOrder(score, productId, selectedAddress, tckn) {
    setModalShow(false);
    setModalCombineShow(false);

    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    if (employeeFirm.memberStatu !== "ASKIDA")
      if (employeeFirm.score.score >= score) {
        if (selectedAddress) {
          await dispatch(
            orderAction.saveOrder(selectedAddress, employeeFirm.id, productId)
          )
            .then(e => {
              setSuccessfulOrderModalShow(true);
              dispatch(catalogAction.catalogChangeState(true));

              // Logo
              dispatch(orderAction.saveOrderLogo(parseInt(e.data.message)));
            })
            .catch(err => {
              if (err.error.response.data)
                if (err.error.response.data.errors) {
                  err.error.response.data.errors.map(item => {
                    item.code === 1041
                      ? setExceededStockModal(true)
                      : showErrMessage(item.message);
                  });
                }
            });
        } else {
          if (tckn) {
            await dispatch(
              orderAction.saveOrder(address, employeeFirm.id, productId, tckn)
            )
              .then(e => {
                setSuccessfulOrderModalShow(true);
                dispatch(catalogAction.catalogChangeState(true));

                // Logo
                dispatch(orderAction.saveOrderLogo(parseInt(e.data.message)));
              })
              .catch(err => {
                if (err.error.response.data)
                  if (err.error.response.data.errors) {
                    err.error.response.data.errors.map(item => {
                      item.code === 1041
                        ? setExceededStockModal(true)
                        : showErrMessage(item.message);
                    });
                  }
              });
          } else {
            await dispatch(
              orderAction.saveOrder(address, employeeFirm.id, productId)
            )
              .then(e => {
                setSuccessfulOrderModalShow(true);
                dispatch(catalogAction.catalogChangeState(true));

                // Logo
                dispatch(orderAction.saveOrderLogo(parseInt(e.data.message)));
              })
              .catch(err => {
                if (err.error.response.data)
                  if (err.error.response.data.errors) {
                    err.error.response.data.errors.map(item => {
                      item.code === 1041
                        ? setExceededStockModal(true)
                        : showErrMessage(item.message);
                    });
                  }
              });
          }
        }

        dispatch(
          authAction.returnNewScoreOfEmployee(
            employeeFirm.firm.id,
            employeeFirm.employee.id
          )
        );
      } else {
        setInsufficientScoreModalShow(true);
      }
    else {
      showErrMessage("Askıda olan kullanıcılar sipariş veremez.");
    }

    await dispatch(catalogAction.setSelectedProduct(null));

    // TODO: Request Kontrol Edilsin.
    //await dispatch(catalogAction.getProductsBySubCatId(0, sortAsc, 0, 0, employeeFirm.id));
  }

  const handleClickOrder = item => {
    if (document.getElementById("checkBox").checked === true) {
      if (item) {
        const tcknVal = document.getElementById("TCKN").value;
        if (tcknVal && tcknVal.length === 11) {
          setTcknErr(false);
          let addressSelection;
          if (
            document.querySelectorAll("#addressSelection")[0] &&
            document.querySelectorAll("#addressSelection")[0].selectedOptions[0]
              .value
          ) {
            addressSelection = document.querySelectorAll("#addressSelection")[0]
              .selectedOptions[0].value;
          }
          confirmOrder(
            selectedProduct.score,
            selectedProduct.id,
            addressSelection,
            tcknVal
          );
        } else {
          setTcknErr(true);
        }
      } else {
        if (
          !document
            .querySelectorAll("#addressSelection")[0]
            .selectedOptions[0].getAttribute("data-districtid")
        ) {
          removeErrMessage();
          showErrMessage(
            "Sipariş vermek için adresinize mahalle bilgisi ekleyiniz."
          );
        } else {
          confirmOrder(
            selectedProduct.score,
            selectedProduct.id,
            document.querySelectorAll("#addressSelection")[0].selectedOptions[0]
              .value
          );
        }
      }
    } else {
      removeErrMessage();
      showErrMessage(
        "Sipariş vermek için koşulları ve şartları onaylamanız gerekmektedir."
      );
    }
  };

  function openModal(item) {
    setShowItem(false);
    document.body.style.overflow = "initial";

    if (item.categoryType === "KOMBINE") {
      setModalCombineShow(true);
      setCartItem(item);
      dispatch(catalogAction.catalogCartItemChangeState(item));
    } else {
      if (employeeFirm.score.score < item.score) {
        setInsufficientScoreModalShow(true);
      } else if (
        employeeFirm.numberOfOrder &&
        employeeFirm.numberOfOrder === 2
      ) {
        setExceededOrderModal(true);
      } else if (employeeAddresses && employeeAddresses.length > 0) {
        setCartItem(item);
        setModalShow(true);
        dispatch(catalogAction.catalogCartItemChangeState(item));
      } else {
        setAddressAlertModalShow(true);
      }
    }
  }

  function setSelectedProduct(selectedProduct) {
    dispatch(catalogAction.setSelectedProduct(selectedProduct));
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  function changeAddress(event) {
    dispatch(orderAction.setAddress(event.target.value));
  }

  //Modal component functions
  function CombineModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="container order-modal">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <span className="active [&.active]:text-primary-500 w-100">
                Sipariş Özeti
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row modal-margin-left mt-4">
              <div className="col-md-3 product-photo">
                <img src={imageShowUrl + props.item.icon} />
              </div>
              <div className="col-md-5 product-description">
                <h4 className="title mb-3">{props.item.name}</h4>
                <div className="description-text mb-3">
                  {props.item.description}
                </div>
                <div>{/* rating */}</div>
                <div className="points">{props.item.score} Puan</div>
              </div>
            </div>
            <div className="row modal-margin-left mt-4">
              <div className="col-sm-12 col-md-12 col-lg-5 mb-3">
                <label htmlFor="TCKN">
                  Maç biletinizi sizin için tanımlayabilmemiz için lütfen TCKN
                  bilginizi giriniz.
                </label>

                <div>
                  <input
                    type="number"
                    id="TCKN"
                    name="TCKN"
                    className="form-control"
                  />
                </div>

                <div className={tcknErr ? "text-danger d-block" : "d-none"}>
                  Lütfen TCKN bilginizi 11 karakter olarak giriniz.
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                  Passolig kartınızın aktif olduğundan emin olunuz.
                </div>
                <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                  Aktif olmayan Passolig kartı sebebiyle harcanan puanların
                  iadesi yapılamaz.
                </div>
                <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                  Girilen TCKN daha sonra değiştirilemez, bilet iade edilemez.
                  Yanlış girilen TCKN bilgisinden Benim Bayim sorumlu değildir.
                </div>
              </div>

              <div className="col-md-12 confirmation-text-title mb-2">
                <input
                  type="checkbox"
                  className="form-check-input modal-margin-left"
                  id="checkBox"
                />
                <label className="form-check-label pl-4" htmlFor="checkBox">
                  Aşağıdaki koşulları ve şartları okudum onaylıyorum.
                </label>
              </div>
              <div className="col-md-12 confirmation-text">
                Gerçek ürün ile resimdeki ürün arasında renk, boy, şekil, baskı
                ve benzeri fiziksel özelliklerde farklılık olabilir. "Siparişi
                Onayla" butonuna tıklamadan önce tüm detayları kontrol
                etmelisiniz çünkü onaylamış olduğunuz siparişler değiştirilemez,
                iptal edilemez, puan iadesi yapılamaz. Benim Bayim’den
                siparişini vermiş olduğunuz ürünler, sipariş verildikten sonra
                yirmi (25) iş günü (resmi tatiller ve bayram tatilleri, ile
                hafta sonu günleri iş günlerine dahil değildir) içinde sistemde
                kayıtlı bulunan ve sipariş adresi olarak seçtiğiniz adresinize
                teslim edilecektir. Siparişiniz için kargo ücreti ödemezsiniz.
                Ürün teslim alınırken, ürünün doğru, sağlam ve eksiksiz olduğunu
                kontrol etmek ve öyle teslim almak, teslim alan kişinin
                sorumluluğundadır. Kontrol sırasında, hasarlı ürün tespit
                edilirse siparişinizi teslim eden kargo firması ile birlikte
                tutanak tutmanız gerekmektedir. Tutanak tutulduktan sonra 0850
                532 5 584 (JTI) numaralı Çağrı Merkezi’mizi arayarak iade
                sürecini başlatabilirsiniz. Tutanak tutulmaması durumunda
                tarafımıza yapılacak hasarlı ürün iadeleri ve itirazlar kabul
                edilmeyecektir. JTI gerekli gördüğü durumlarda Benim Bayim
                Dükkanı’nda yer alan ürünlerin puanlarını değiştirme veya
                dilediği ürünü Benim Bayim Dükkanı’ndan kaldırma hakkına
                sahiptir. Sitede yer alan ürünler tedarikçi stokları ile
                sınırlıdır. Stoğu tükenmiş ürünler için Kullanıcı hiçbir hak
                iddiasında bulunamaz. JTI, Benim Bayim Dükkanı’nda yer alan
                ürünlerin tedariğinde sorun çıkması durumunda, ürünlerin
                muadilini veya bir üst modelini gönderebilir. Kullanıcı,
                kendisine gönderilen ürünün Benim Bayim Dükkanı’nda sergilenen
                ürün olmadığına dair JTI’dan hak talep edemez. 22 Temmuz 2024 -
                04 Mayıs 2025 tarihleri arasında sürecek olan Benim Bayim’in
                2024-2025 dönemi boyunca Benim Bayim Dükkanı’ndaki hediyelerden
                en fazla 3 adet sipariş verebilirsiniz. Bu dönemde kazandığınız
                puanlarınızı 04 Mayıs 2025 günü saat 23:59'a kadar
                harcayabilirsiniz. 04 Mayıs 2025 günü saat 23:59 itibariyle
                kalan puanlar silinecektir. ‘’Siparişi Onayla’’ butonuna
                tıkladığınızda burada yazılı olan şartları kabul etmiş
                sayılacaksınız. 3. bir kişi adına tanımlanmak üzere maç bileti
                almak istemeniz halinde, 3. kişinin kimlik bilgilerini Benim
                Bayim Sitesi’ne aktarmadan önce, söz konusu kişiden bilgilerinin
                siteye aktarılması konusunda onayını almanız ve bu kişiyi sitede
                yer alan Şart ve Koşullar konusunda bilgilendirmeniz
                gerekmektedir. Aksi takdirde doğacak her türlü risk, hukuki ve
                cezai sorumluluk münferiden tarafınıza ait olacak ve bu konuda
                JTI sorumlu tutulamayacaktır.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={!modalCombineShow}
              onClick={() => handleClickOrder(props.item)}
            >
              Siparişi Onayla
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {orderNumber && !orderNumberConfirmation ? (
          <div className="container order-modal order-number-2">
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <span className="active [&.active]:text-primary-500 w-100">
                  Sipariş Özeti
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row modal-margin-left mt-4">
                <div className="col-md-3 product-photo">
                  <img src={imageShowUrl + props.item.icon} />
                </div>
                <div className="col-md-9 product-description">
                  <h4 className="mb-4 text-xl font-semibold text-slate-700 sm:mb-12 sm:text-2xl">
                    {props.item.name}
                  </h4>
                  <div className="mb-4 [&>p]:mb-6 flex items-center gap-2 font-bold text-primary-500 sm:text-2xl">
                    {props.item.score} Puan
                  </div>
                  <div className="text-sm text-slate-500 max-sm:text-xs [&>p]:leading-6">
                    {props.item.description}
                  </div>
                </div>
              </div>

              {orderNumber?.orderCount === "1" && (
                <div className="row modal-margin-left">
                  <div className="warning-modal-section text-center mt-4 mb-2">
                    <h4 className="mt-0 mb-0">
                      Dikkat; bir dönem içinde Benim Bayim Dükkanı’ndan en fazla
                      üç hediye siparişi verebilirsiniz.
                    </h4>
                    <div className="warning-title mt-4">
                      Şu an üçüncü hediye siparişinizi veriyorsunuz.
                    </div>
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="relative my-2 flex items-center justify-center rounded-full bg-primary-500 p-3 text-center text-white max-sm:text-sm sm:px-6 sm:py-4 max-w-[310px] w-100"
                disabled={!modalShow}
                onClick={() => setOrderNumberConfirmation(true)}
              >
                Sipariş Adımına Devam Et
                <IoArrowForward className="absolute right-6 top-1/2 ml-auto h-5 w-5 shrink-0 -translate-y-1/2 transform max-sm:hidden" />
              </Button>
            </Modal.Footer>
          </div>
        ) : (
          <div className="container order-modal">
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <span className="active [&.active]:text-primary-500 w-100">
                  Sipariş Özeti
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row modal-margin-left mt-4">
                <div className="col-md-3 product-photo">
                  <img src={imageShowUrl + props.item.icon} />
                </div>
                <div className="col-md-5 product-description">
                  <h4 className="title mb-3">{props.item.name}</h4>
                  <div className="description-text mb-3">
                    {props.item.description}
                  </div>
                  <div>{/* rating */}</div>
                  <div className="points">{props.item.score} Puan</div>
                </div>
                <div className="col-md-4 adress-selection">
                  <h4 className="mb-1">Adres Bilgisi</h4>
                  <div className="form-group">
                    <select
                      className="form-control"
                      value={address}
                      onChange={value => {
                        changeAddress(value);
                      }}
                      id="addressSelection"
                    >
                      {employeeAddresses && employeeAddresses.length > 0
                        ? employeeAddresses.map((item2, index2) => {
                            return (
                              <option
                                key={index2}
                                data-districtid={
                                  item2.districtId && item2.districtId
                                }
                                data-orderaddress={
                                  item2.orderAddress && item2.orderAddress
                                }
                                value={item2.name ? item2.name : ""}
                              >
                                {item2.name && item2.name}
                              </option>
                            );
                          })
                        : null}
                      )
                    </select>
                  </div>
                </div>
              </div>
              <div className="row modal-margin-left mt-5">
                <div className="col-md-12 confirmation-text-title mb-2">
                  <input
                    type="checkbox"
                    className="form-check-input modal-margin-left"
                    id="checkBox"
                  />
                  <label className="form-check-label pl-4" htmlFor="checkBox">
                    Aşağıdaki koşulları ve şartları okudum onaylıyorum.
                  </label>
                </div>
                <div className="col-md-12 confirmation-text">
                  Gerçek ürün ile resimdeki ürün arasında renk, boy, şekil,
                  baskı ve benzeri fiziksel özelliklerde farklılık olabilir.
                  "Siparişi Onayla" butonuna tıklamadan önce tüm detayları
                  kontrol etmelisiniz çünkü onaylamış olduğunuz siparişler
                  değiştirilemez, iptal edilemez, puan iadesi yapılamaz. Benim
                  Bayim’den siparişini vermiş olduğunuz ürünler, sipariş
                  verildikten sonra yirmi (25) iş günü (resmi tatiller ve bayram
                  tatilleri, ile hafta sonu günleri iş günlerine dahil değildir)
                  içinde sistemde kayıtlı bulunan ve sipariş adresi olarak
                  seçtiğiniz adresinize teslim edilecektir. Siparişiniz için
                  kargo ücreti ödemezsiniz. Ürün teslim alınırken, ürünün doğru,
                  sağlam ve eksiksiz olduğunu kontrol etmek ve öyle teslim
                  almak, teslim alan kişinin sorumluluğundadır. Kontrol
                  sırasında, hasarlı ürün tespit edilirse siparişinizi teslim
                  eden kargo firması ile birlikte tutanak tutmanız
                  gerekmektedir. Tutanak tutulduktan sonra 0850 532 5 584 (JTI)
                  numaralı Çağrı Merkezi’mizi arayarak iade sürecini
                  başlatabilirsiniz. Tutanak tutulmaması durumunda tarafımıza
                  yapılacak hasarlı ürün iadeleri ve itirazlar kabul
                  edilmeyecektir. JTI gerekli gördüğü durumlarda Benim Bayim
                  Dükkanı’nda yer alan ürünlerin puanlarını değiştirme veya
                  dilediği ürünü Benim Bayim Dükkanı’ndan kaldırma hakkına
                  sahiptir. Sitede yer alan ürünler tedarikçi stokları ile
                  sınırlıdır. Stoğu tükenmiş ürünler için Kullanıcı hiçbir hak
                  iddiasında bulunamaz. JTI, Benim Bayim Dükkanı’nda yer alan
                  ürünlerin tedariğinde sorun çıkması durumunda, ürünlerin
                  muadilini veya bir üst modelini gönderebilir. Kullanıcı,
                  kendisine gönderilen ürünün Benim Bayim Dükkanı’nda sergilenen
                  ürün olmadığına dair JTI’dan hak talep edemez. 22 Temmuz 2024
                  - 04 Mayıs 2025 tarihleri arasında sürecek olan Benim Bayim’in
                  2024-2025 dönemi boyunca Benim Bayim Dükkanı’ndaki
                  hediyelerden en fazla 3 adet sipariş verebilirsiniz. Bu
                  dönemde kazandığınız puanlarınızı 04 Mayıs 2025 günü saat
                  23:59'a kadar harcayabilirsiniz. 04 Mayıs 2025 günü saat 23:59
                  itibariyle kalan puanlar silinecektir. ‘’Siparişi Onayla’’
                  butonuna tıkladığınızda burada yazılı olan şartları kabul
                  etmiş sayılacaksınız.
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="relative my-2 flex items-center justify-center rounded-full bg-primary-500 p-3 text-center text-white max-sm:text-sm sm:mt-6 sm:px-6 sm:py-4 max-w-[250px] w-100"
                disabled={!modalShow}
                onClick={() => handleClickOrder()}
              >
                Siparişi Onayla
                <IoArrowForward className="absolute right-6 top-1/2 ml-auto h-5 w-5 shrink-0 -translate-y-1/2 transform max-sm:hidden" />
              </Button>
            </Modal.Footer>
          </div>
        )}
      </Modal>
    );
  }

  function AddressAlertModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="container order-modal">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Uyarı</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="warning-modal-section text-center mt-5">
                <img src={UnhappyIcon} alt="" />
                <h4 className="mt-4">Sipariş adresiniz eksik!</h4>
                <div className="warning-title mt-4">
                  Sipariş vermeden önce Adres Bilgilerim alanından hediye
                  siparişinizin gönderimi için kullanacağınız adres bilgisini
                  girmeli ve adresinizin “Sipariş Adresi” olarak seçildiğinden
                  emin olmalısınız.
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Link
              style={{ color: "white" }}
              to={{
                pathname: "/userportal/profile"
              }}
            >
              <Button
                onClick={() => {
                  goToAdress("addressinfo", 1);
                }}
              >
                Yeni Sipariş Adresi Tanımla
              </Button>
            </Link>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }

  function ExceededOrderModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="container order-modal">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Uyarı</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="warning-modal-section text-center mt-5">
                <img src={UnhappyIcon} alt="" />
                <h4 className="mt-4">Sipariş Limit Aşımı !</h4>
                <div className="warning-title mt-4">
                  Benim Bayim’in 2024-2025 dönemi boyunca Benim Bayim
                  Dükkanı’ndaki hediyelerden en fazla 3 kere sipariş
                  verebilirsiniz.
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={props.onHide}
              className="relative my-4 flex items-center justify-center rounded-full bg-primary-500 !important hover:bg-primary-500 !important p-3 text-center text-white max-sm:text-sm sm:mt-6 sm:px-6 sm:py-4"
            >
              Diğer Ürünler
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }

  function ExceededStockModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="container order-modal">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Uyarı</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="warning-modal-section text-center mt-5">
                <img src={UnhappyIcon} alt="" />
                <h4 className="mt-4">Yetersiz Stok!</h4>
                <div className="warning-title mt-4">
                  Bu üründe yeterli stok olmadığı için sipariş verilemedi.
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={props.onHide}
              className="relative my-2 flex items-center justify-center rounded-full bg-primary-500 !important hover:bg-primary-500 !important p-3 text-center text-white max-sm:text-sm sm:mt-6 sm:px-6 sm:py-4"
            >
              Diğer Ürünler
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }

  function SuccessfulOrderModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="container order-modal">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <span className="active [&.active]:text-primary-500 w-100">
                Sipariş Durumu
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="order-success-section text-center mt-5">
                {catalogCartItemState ? (
                  <img src={imageShowUrl + catalogCartItemState.icon} />
                ) : (
                  <img src={imageShowUrl + props.item.icon} />
                )}
                <h4 className="mt-4">İyi Günlerde Kullanın!</h4>

                {catalogCartItemState.categoryType !== "KOMBINE" &&
                  catalogCartItemState.categoryName !== "FIRSATLAR" &&
                  catalogCartItemState.categoryName !== "HEDİYE ÇEKİ" && (
                    <div className="success-title mt-4">
                      Hediyeniz 25 iş günü içerisinde kargo aracılığıyla size
                      ulaşacaktır. Tedarik ve teslimat sürelerinde yaşanabilecek
                      aksaklıklar sebebiyle teslimat değişkenlik gösterebilir.
                    </div>
                  )}

                {catalogCartItemState.categoryType !== "KOMBINE" &&
                  (catalogCartItemState.categoryName === "FIRSATLAR" ||
                    catalogCartItemState.categoryName === "HEDİYE ÇEKİ") && (
                    <div className="success-title mt-4">
                      Aldığınız kod mesajlarım bölümüne iletilecektir.
                      Mesajlarım bölümünü kontrol etmeyi unutmayın.
                    </div>
                  )}

                <div
                  className={
                    catalogCartItemState.categoryType === "KOMBINE" ||
                    catalogCartItemState.categoryName === "FIRSATLAR" ||
                    catalogCartItemState.categoryName === "HEDİYE ÇEKİ"
                      ? "success-title mt-4"
                      : "warning-text mt-4 mb-5"
                  }
                >
                  Siparişiniz ile ilgili sorularınız için Sıkça Sorulan Sorular
                  bölümünden yardım alabilir, Benim Bayim Çağrı Merkezi'ni 0850
                  532 5 584 nolu telefondan Pazartesi ila Cuma günleri
                  08:30-17:30 saatleri arasında arayabilir veya Bize Ulaşın'dan
                  bize erişebilirsiniz.
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="success-footer">
              <div className="point-left">
                Kalan Puanınız:{" "}
                <span>
                  {employeeFirm.score ? employeeFirm.score.score : null}
                </span>
              </div>
              <Link
                className="button"
                to={{
                  pathname: "/userportal/profile"
                }}
                onClick={() => {
                  goToAdress("orders", 5);
                }}
              >
                Siparişlerim
              </Link>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }

  function InsufficientScoreModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="container order-modal">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <span className="active [&.active]:text-primary-500 w-100">
                Sipariş Durumu
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="warning-modal-section text-center my-4">
                <img src={UnhappyIcon} alt="" />
                <h4 className="mt-4">Puanınız Yetersiz.</h4>
                <div className="warning-title mt-4">
                  Bu ürünü sipariş etmek için yeterli puana sahip değilsiniz.
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="relative my-2 flex items-center justify-center rounded-full bg-primary-500 p-3 text-center text-white max-sm:text-sm sm:mt-6 sm:px-6 sm:py-4 max-w-[250px] w-100"
              onClick={props.onHide}
            >
              Diğer Ürünler
              <IoArrowForward className="absolute right-6 top-1/2 ml-auto h-5 w-5 shrink-0 -translate-y-1/2 transform max-sm:hidden" />
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }

  const handleSimilarProducts = id => {
    if (employeeFirm?.id) {
      dispatch(catalogAction.getSimilarProducts(id, employeeFirm?.id));
    }
  };

  const handleAddFavourite = (e, productId) => {
    e.preventDefault();

    if (productId) {
      dispatch(
        catalogAction.saveFavouriteProduct(employeeFirm.id, productId)
      ).then(res => {
        dispatch(catalogAction.getSimilarProducts(productId, employeeFirm?.id));
      });
    }
  };

  const handleRemoveFavourite = (e, productId) => {
    e.preventDefault();

    if (productId) {
      dispatch(
        catalogAction.removeFavouriteProduct(employeeFirm.id, productId)
      ).then(res => {
        dispatch(catalogAction.getSimilarProducts(productId, employeeFirm?.id));
      });
    }
  };

  return (
    <>
      <div className="max-sm:mt-4 md:col-span-8 lg:col-span-9">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold text-slate-700 sm:text-2xl">
            Tüm Ürünler
          </h2>
          <div className="rounded-lg border-2 border-slate-200 bg-white px-2 py-2 text-slate-500 max-sm:text-sm">
            <select
              className="sorting-types"
              value={sortingType}
              onChange={e => {
                const selectedSortingType = e.target.value;
                dispatch(catalogAction.setSortingType(selectedSortingType));

                if (sortByTypeAsc === "ALL") {
                  if (selectedSortingType === "Puana göre azalan") {
                    getSortedProducts(false, "DESC", 0);
                  } else if (selectedSortingType === "Puana göre artan") {
                    getSortedProducts(true, true, 0);
                  }
                } else {
                  if (selectedSortingType === "Puana göre azalan") {
                    getSortedProducts(false, "DESC", employeeFirm.score.score);
                  } else if (selectedSortingType === "Puana göre artan") {
                    getSortedProducts(true, true, employeeFirm.score.score);
                  }
                }
              }}
            >
              <option value="Puana göre azalan">Puana göre azalan</option>
              <option value="Puana göre artan">Puana göre artan</option>
            </select>
          </div>
        </div>

        <div className="mt-6 grid grid-cols-2 gap-2 sm:mt-12 lg:grid-cols-3 lg:gap-4">
          {productList && productList.length > 0 ? (
            productList.map((item, index) => {
              return (
                <a
                  href="#"
                  className={
                    item.noneStock
                      ? "pointer-events-none select-none flex flex-col rounded-xl bg-white p-3 shadow-lg shadow-slate-200 sm:p-6 sm:pb-12 position-relative"
                      : "select-none flex flex-col rounded-xl bg-white p-3 shadow-lg shadow-slate-200 sm:p-6 sm:pb-12 position-relative"
                  }
                  key={index}
                  onClick={e => {
                    e.preventDefault();
                    setSelectedProduct(item);
                    setShowItem(true);
                    document.body.style.overflow = "hidden";
                    handleSimilarProducts(item.id);
                  }}
                >
                  {item.topIcon && (
                    <div className="green-package">
                      <span>{item.topIconText}</span>
                    </div>
                  )}

                  {item.topIcon2 && (
                    <div className="orange-package">
                      <span>{item.topIcon2Text}</span>
                    </div>
                  )}

                  <div className="min-h-[200px] mb-7 flex flex-col justify-center align-center">
                    <img
                      src={
                        imageShowUrl +
                        (productList && productList.length > 0
                          ? productList[index].icon
                          : "")
                      }
                      className="max-h-[200px] mx-auto"
                      alt=""
                    />
                  </div>

                  <div className="mt-auto">
                    {item.criticalStock && item.criticalStock > 0 ? (
                      <span className="text-xs font-semibold text-slate-500">
                        Son {item.criticalStock} Adet
                      </span>
                    ) : (
                      ""
                    )}
                    <h5 className="mt-2 text-sm font-medium text-slate-700 sm:text-xl">
                      {productList &&
                        productList.length > 0 &&
                        productList[index].name &&
                        productList[index].name}
                    </h5>

                    {item.noneStock ? (
                      <div className="relative mt-4 flex items-center justify-center rounded-full bg-danger p-3 text-center text-white max-sm:text-sm sm:mt-6 sm:px-6 sm:py-4">
                        Tükendi
                      </div>
                    ) : (
                      <div className="btn btn-progress relative mt-4 flex items-center justify-center rounded-full p-3 text-center text-white max-sm:text-sm sm:mt-6 sm:px-6 sm:py-4">
                        <span className="position-relative d-flex justify-center items-center">
                          {item.score} Puan
                          <IoArrowForward className="!ml-1" />
                        </span>
                        <span
                          className="bg-primary-700"
                          style={{
                            width:
                              Math.round(
                                (employeeFirm.score.score / item.score) * 100
                              ) + "%"
                          }}
                        ></span>
                      </div>
                    )}
                  </div>
                </a>
              );
            })
          ) : sortByTypeAsc === "SCORE" ? (
            <p className="absolute">
              Üzgünüz, puanınıza karşılık gelen bir hediye bulunmamaktadır.
            </p>
          ) : (
            <p className="absolute">
              Üzgünüz, bu kategoride henüz bir hediye bulunmamaktadır.
            </p>
          )}
        </div>
        {productList && productList.length > 0 && (
          <Pagination
            activePage={activePage}
            totalItemsCount={12 * totalPages}
            itemsCountPerPage={12}
            pageRangeDisplayed={
              totalPages !== null && totalPages > 5 ? 5 : totalPages
            }
            itemClass="page-item"
            linkClass="page-link"
            onChange={handlePageChange.bind(this)}
          />
        )}
      </div>

      <div>
        <CombineModal
          item={cartItem}
          show={modalCombineShow}
          onHide={() => {
            setModalCombineShow(false);
            setOrderNumberConfirmation(false);
          }}
        />

        <MyVerticallyCenteredModal
          item={cartItem}
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            setOrderNumberConfirmation(false);
          }}
        />
        <AddressAlertModal
          show={addressAlertModalShow}
          onHide={() => setAddressAlertModalShow(false)}
        />
        <SuccessfulOrderModal
          item={cartItem}
          show={catalogModalState}
          onHide={() => dispatch(catalogAction.catalogChangeState(false))}
        />
        <InsufficientScoreModal
          item={cartItem}
          show={insufficientScoreModalShow}
          onHide={() => setInsufficientScoreModalShow(false)}
        />
        <ExceededOrderModal
          show={exceededOrderModal}
          onHide={() => setExceededOrderModal(false)}
        />
        <ExceededStockModal
          show={exceededStockModal}
          onHide={() => setExceededStockModal(false)}
        />

        <GlobalLoader isLoading={isLoading} />
      </div>

      {showItem && (
        <div className="fixed inset-0 z-30 bg-slate-900/75 backdrop-blur-sm">
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-2 sm:p-4">
              <div className="relative w-[1300px] max-w-full rounded-xl bg-white shadow-md">
                <button
                  className="absolute right-4 top-4 md:right-6 md:top-6"
                  onClick={() => {
                    setShowItem(false);
                    document.body.style.overflow = "visible";
                  }}
                >
                  <IoCloseOutline className=" h-8 w-8 text-slate-500" />
                </button>
                <div className="!grid !grid-cols-12 items-center !gap-6 !p-4 max-lg:!pt-16 md:!gap-12 lg:!gap-16 lg:!p-16">
                  <div className="col-span-12 md:col-span-6 lg:col-span-5">
                    {selectedProduct && selectedProduct.icon && (
                      <div>
                        <img
                          src={imageShowUrl + selectedProduct.icon}
                          alt=""
                          className="w-auto rounded-lg border-2 border-slate-300 max-h-[600px] m-auto"
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-7">
                    <h1 className="mb-8 text-xl font-semibold text-slate-700 sm:mb-12 sm:text-3xl mr-4">
                      {selectedProduct &&
                        selectedProduct.name &&
                        selectedProduct.name}
                    </h1>

                    <div className="flex items-center justify-between gap-4 max-sm:flex-col">
                      <div className="w-full">
                        {selectedProduct &&
                          selectedProduct.criticalStock != 0 && (
                            <span className="text-xs font-semibold text-slate-600">
                              Son {selectedProduct.criticalStock} Adet
                            </span>
                          )}
                        <div className="mt-2 flex items-center gap-2 font-bold text-primary-500">
                          <span className="text-4xl">
                            {selectedProduct && selectedProduct.score}
                          </span>
                          <span>Puan</span>
                        </div>
                        {selectedProduct?.score &&
                          employeeFirm?.score?.score &&
                          selectedProduct?.score >
                            employeeFirm?.score?.score && (
                            <div className="mt-3 flex items-center gap-2 text-sm font-normal text-orange">
                              <FaInfoCircle className="h-4 w-4" />{" "}
                              {selectedProduct?.score -
                                employeeFirm?.score?.score}{" "}
                              Puan’a ihtiyacın var
                            </div>
                          )}

                        <div className="!mt-3">
                          {similarProducts?.favouriteStatus ? (
                            <a
                              href="#"
                              className="flex flex-row items-center !cursor-pointer"
                              onClick={e =>
                                handleRemoveFavourite(e, similarProducts?.id)
                              }
                            >
                              <FaHeart className="h-4 w-4 text-red-500 inline-block !mr-1" />
                              <span className="inline-block text-sm text-red-500">
                                Favorilerimden Çıkar
                              </span>
                            </a>
                          ) : (
                            <a
                              href="#"
                              className="flex flex-row items-center !cursor-pointer"
                              onClick={e =>
                                handleAddFavourite(e, similarProducts?.id)
                              }
                            >
                              <FaHeart className="h-4 w-4 text-gray-500 inline-block !mr-1" />
                              <span className="inline-block text-sm text-gray-500">
                                Favorilerime Ekle
                              </span>
                            </a>
                          )}
                        </div>
                      </div>
                      <button
                        className="mt-6 flex items-center justify-center gap-4 whitespace-nowrap rounded-full bg-primary-500 p-3 text-center text-white max-sm:w-full sm:px-6 sm:py-4 min-w-[170px]"
                        onClick={() => openModal(selectedProduct)}
                      >
                        <FaShoppingCart className="h-5 w-5" />
                        Sipariş Ver
                      </button>
                    </div>
                    {/* <div className="mt-12 text-slate-500 max-sm:text-xs">
                    <div className="grid grid-cols-2 border-b-2 border-slate-200 py-4">
                      <span>Adımsayar</span>
                      <span>Var</span>
                    </div>
                    <div className="grid grid-cols-2 border-b-2 border-slate-200 py-4">
                      <span>Cinsiyet</span>
                      <span>Kadın, Erkek</span>
                    </div>
                    <div className="grid grid-cols-2 border-b-2 border-slate-200 py-4">
                      <span>GPS</span>
                      <span>Var</span>
                    </div>
                    <div className="grid grid-cols-2 border-b-2 border-slate-200 py-4">
                      <span>Kamera</span>
                      <span>Var</span>
                    </div>
                  </div> */}
                    <div className="mt-6 text-sm text-slate-500 max-sm:text-xs sm:mt-12 [&>p]:mb-6 [&>p]:leading-6">
                      <p>
                        {selectedProduct &&
                          selectedProduct.description &&
                          selectedProduct.description}
                      </p>
                    </div>
                  </div>
                </div>

                {similarProducts?.similarProductDTOList &&
                similarProducts?.similarProductDTOList.length > 0 ? (
                  <div className="rounded-b-xl bg-slate-100 px-2 py-6 sm:px-4 sm:py-12">
                    <h3 className="text-center text-lg font-semibold text-slate-700 sm:text-2xl">
                      Benzer Puanlı Ürünler
                    </h3>
                    <div className="mt-6 grid grid-cols-2 gap-2 sm:mt-12 lg:grid-cols-4 lg:gap-4">
                      {similarProducts.similarProductDTOList.map(
                        similarProduct => (
                          <a
                            href="#"
                            className="flex flex-col rounded-xl bg-white p-3 shadow-lg shadow-slate-200 sm:p-6 sm:pb-12"
                            key={similarProduct.id}
                            onClick={e => {
                              e.preventDefault();
                              setSelectedProduct(similarProduct);
                              setShowItem(true);
                              document.body.style.overflow = "hidden";
                              handleSimilarProducts(similarProduct.id);
                            }}
                          >
                            {similarProduct.icon && (
                              <img
                                src={imageShowUrl + similarProduct.icon}
                                alt=""
                                className="mb-4 mx-auto sm:mb-7 max-h-[200px]"
                              />
                            )}
                            <div className="mt-auto">
                              {similarProduct.criticalStock > 0 && (
                                <span className="text-xs font-semibold text-slate-500">
                                  Son {similarProduct.criticalStock} Adet
                                </span>
                              )}
                              <h5 className="mt-2 text-sm font-medium text-slate-700 sm:text-xl">
                                {similarProduct.name && similarProduct.name}
                              </h5>
                              <div className="relative mt-4 flex items-center justify-center rounded-full bg-primary-500 p-3 text-center text-white max-sm:text-sm sm:mt-6 sm:px-6 sm:py-4">
                                {similarProduct.score && similarProduct.score}{" "}
                                Puan{" "}
                                <IoArrowForward className="absolute right-6 top-1/2 ml-auto h-5 w-5 shrink-0 -translate-y-1/2 transform max-sm:hidden" />
                              </div>
                            </div>
                          </a>
                        )
                      )}
                    </div>
                  </div>
                ) : (
                  "Malesef, benzer ürün bulunmamaktadır!"
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DynamicCatalogPage;
