import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pickFindAction, authAction } from "redux/modules";
import showErrMessage from "hooks/showErrMessage";
import showMessage from "hooks/showMessage";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";
import CardIcon from "assets/images/new-design/game/card-icon.png";

import Camel from "assets/images/brands/camel.png";
import Winston from "assets/images/brands/winston.png";
import Ld from "assets/images/brands/ld.png";
import MonteCarlo from "assets/images/brands/monte-carlo.png";

import { FaInfoCircle } from "react-icons/fa";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";

import "./Style.scss";

const PickFindQuestion = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const pickFindList = useSelector(store => store.pickFind.pickFindList);
  var pickFindGameTop = null;
  var pickFindGameBottom = null;
  var pickFindGameButton = null;
  const isLoading = useSelector(store => store.pickFind.isLoading);

  const [selectedTop, setSelectedTop] = useState(null);
  const [selectedBottom, setSelectedBottom] = useState(null);
  const choiceTop = [];
  const choiceBottom = [];
  const [redirectAnswer, setRedirectAnswer] = useState(false);
  const [selectedTopIndex, setSelectedTopIndex] = useState(null);
  const [selectedBottomIndex, setSelectedBottomIndex] = useState(null);

  useEffect(() => {
    dispatch(
      pickFindAction.getPickFindByFirmId(employeeFirm.firm.id, employeeFirm.id)
    );
    return () => {};
  }, []);

  function scrollToAnswers() {
    var access = document.getElementById("pickFindAnswers");
    access.scrollIntoView({ behavior: "smooth" }, true);
  }
  function selectedTopControl(brandNameList, index2, brandPropertyList) {
    brandNameList.map((item, index) => {
      if (index === index2) {
        brandNameList[index2].selectedCard = 1;
      } else if (item.selectedCard !== 2) brandNameList[index].selectedCard = 0;
    });

    brandPropertyList.map((item, index) => {
      if (item.selectedCard === 3 && item.selectedCard !== 2)
        brandPropertyList[index].selectedCard = 0;
    });
  }

  function selectedBottomControl(brandPropertyList, index2, brandNameList) {
    brandPropertyList.map((item, index) => {
      if (index === index2) {
        brandPropertyList[index2].selectedCard = 1;
      } else if (item.selectedCard !== 2)
        brandPropertyList[index].selectedCard = 0;
    });

    brandNameList.map((item, index) => {
      if (item.selectedCard === 3 && item.selectedCard !== 2)
        brandNameList[index].selectedCard = 0;
    });
  }

  function showBrandName(brandNameList, brandPropertyList) {
    var brandNames = null;
    brandNames = brandNameList.map((itemName, index) => (
      <div
        key={index}
        className={
          itemName.selectedCard === 2
            ? "pick-find pick-find-success"
            : itemName.selectedCard === 3
            ? "pick-find pick-find-error"
            : itemName.selectedCard === 1
            ? "pick-find pick-find-active"
            : "pick-find"
        }
        data-value={index}
        style={{
          pointerEvents: itemName.selectedCard === 2 ? "none" : ""
        }}
        onClick={() => {
          selectedTopControl(brandNameList, index, brandPropertyList);
          choiceTop.push(itemName.id);
          setSelectedTop(choiceTop);
          setSelectedTopIndex(index);
        }}
      >
        {/* Polygons - Success - Error - Active - Default */}
        <svg
          width="268"
          height="301"
          viewBox="0 0 268 301"
          xmlns="http://www.w3.org/2000/svg"
          className="pick-find-polygon-success"
        >
          <path
            d="m145.998 3.928 109.058 62.965a24 24 0 0 1 12 20.785v125.93a24 24 0 0 1-12 20.784l-109.058 62.965a24 24 0 0 1-24 0L12.939 234.392a24 24 0 0 1-12-20.784V87.678a24 24 0 0 1 12-20.785L121.998 3.928a24 24 0 0 1 24 0z"
            fill="#FFF"
            fillRule="evenodd"
          />
        </svg>

        <svg
          width="268"
          height="301"
          viewBox="0 0 268 301"
          xmlns="http://www.w3.org/2000/svg"
          className="pick-find-polygon-error"
        >
          <path
            d="m145.998 3.928 109.058 62.965a24 24 0 0 1 12 20.785v125.93a24 24 0 0 1-12 20.784l-109.058 62.965a24 24 0 0 1-24 0L12.939 234.392a24 24 0 0 1-12-20.784V87.678a24 24 0 0 1 12-20.785L121.998 3.928a24 24 0 0 1 24 0z"
            fill="#FFF"
            fillRule="evenodd"
          />
        </svg>

        <svg
          width="268"
          height="301"
          viewBox="0 0 268 301"
          xmlns="http://www.w3.org/2000/svg"
          className="pick-find-polygon-active"
        >
          <path
            d="m145.998 3.928 109.058 62.965a24 24 0 0 1 12 20.785v125.93a24 24 0 0 1-12 20.784l-109.058 62.965a24 24 0 0 1-24 0L12.939 234.392a24 24 0 0 1-12-20.784V87.678a24 24 0 0 1 12-20.785L121.998 3.928a24 24 0 0 1 24 0z"
            fill="#FFF"
            fillRule="evenodd"
          />
        </svg>

        <svg
          width="268"
          height="301"
          viewBox="0 0 268 301"
          xmlns="http://www.w3.org/2000/svg"
          className="pick-find-polygon"
        >
          <path
            d="m145.998 3.928 109.058 62.965a24 24 0 0 1 12 20.785v125.93a24 24 0 0 1-12 20.784l-109.058 62.965a24 24 0 0 1-24 0L12.939 234.392a24 24 0 0 1-12-20.784V87.678a24 24 0 0 1 12-20.785L121.998 3.928a24 24 0 0 1 24 0z"
            fill="#FFF"
            fillRule="evenodd"
          />
        </svg>
        {/* End Polygons - Success - Error - Default */}

        <div className="pick-find-content">
          <input type="hidden" name="cevap1" />
          {itemName.name && itemName.name.search("Monte Carlo") > -1 ? (
            <img src={MonteCarlo} />
          ) : itemName.name.search("Camel") > -1 ? (
            <img src={Camel} />
          ) : itemName.name.search("Winston") > -1 ? (
            <img src={Winston} />
          ) : (
            <img src={Ld} />
          )}

          <p className="mt-3 mb-0 px-4 text-4xl max-w-[268px] text-tab-700 font-semibold flex flex-col flex-wrap">
            {itemName.name && itemName.name}
          </p>
        </div>

        {/* <div
          key={itemName.id}
          className={`soru-box games-box ${
            itemName.selectedCard === 2
              ? "green"
              : itemName.selectedCard === 3
              ? "red"
              : ""
          } mb-3 d-flex align-items-center ${
            itemName.selectedCard === 1
              ? "active"
              : itemName.selectedCard === 0
              ? "dshadow"
              : ""
          }`}
          style={{
            pointerEvents: itemName.selectedCard === 2 ? "none" : ""
          }}
          data-value={index}
          onClick={() => {
            selectedTopControl(brandNameList, index, brandPropertyList);
            choiceTop.push(itemName.id);
            setSelectedTop(choiceTop);
            setSelectedTopIndex(index);
          }}
        >
          <input type="hidden" name="cevap1" />
          <h2 className="text-center flex-fill">{itemName.name} </h2>
        </div> */}
      </div>
    ));
    return brandNames;
  }

  function showBrandPropery(brandPropertyList, brandNameList) {
    var brandStyleProperties = null;
    brandStyleProperties = brandPropertyList.map((itemPro, index2) => (
      <div
        key={index2}
        className={
          itemPro.selectedCard === 2
            ? "pick-find pick-find-success"
            : itemPro.selectedCard === 3
            ? "pick-find pick-find-error"
            : itemPro.selectedCard === 1
            ? "pick-find pick-find-active"
            : "pick-find"
        }
        data-value={index2}
        style={{
          pointerEvents: itemPro.selectedCard === 2 ? "none" : ""
        }}
        onClick={() => {
          selectedBottomControl(brandPropertyList, index2, brandNameList);
          choiceBottom.push(itemPro.brandStyleId);
          setSelectedBottom(choiceBottom);
          setSelectedBottomIndex(index2);
        }}
      >
        {/* Polygons - Success - Error - Active - Default */}
        <svg
          width="268"
          height="301"
          viewBox="0 0 268 301"
          xmlns="http://www.w3.org/2000/svg"
          className="pick-find-polygon-success"
        >
          <path
            d="m145.998 3.928 109.058 62.965a24 24 0 0 1 12 20.785v125.93a24 24 0 0 1-12 20.784l-109.058 62.965a24 24 0 0 1-24 0L12.939 234.392a24 24 0 0 1-12-20.784V87.678a24 24 0 0 1 12-20.785L121.998 3.928a24 24 0 0 1 24 0z"
            fill="#FFF"
            fillRule="evenodd"
          />
        </svg>

        <svg
          width="268"
          height="301"
          viewBox="0 0 268 301"
          xmlns="http://www.w3.org/2000/svg"
          className="pick-find-polygon-error"
        >
          <path
            d="m145.998 3.928 109.058 62.965a24 24 0 0 1 12 20.785v125.93a24 24 0 0 1-12 20.784l-109.058 62.965a24 24 0 0 1-24 0L12.939 234.392a24 24 0 0 1-12-20.784V87.678a24 24 0 0 1 12-20.785L121.998 3.928a24 24 0 0 1 24 0z"
            fill="#FFF"
            fillRule="evenodd"
          />
        </svg>

        <svg
          width="268"
          height="301"
          viewBox="0 0 268 301"
          xmlns="http://www.w3.org/2000/svg"
          className="pick-find-polygon-active"
        >
          <path
            d="m145.998 3.928 109.058 62.965a24 24 0 0 1 12 20.785v125.93a24 24 0 0 1-12 20.784l-109.058 62.965a24 24 0 0 1-24 0L12.939 234.392a24 24 0 0 1-12-20.784V87.678a24 24 0 0 1 12-20.785L121.998 3.928a24 24 0 0 1 24 0z"
            fill="#FFF"
            fillRule="evenodd"
          />
        </svg>

        <svg
          width="268"
          height="301"
          viewBox="0 0 268 301"
          xmlns="http://www.w3.org/2000/svg"
          className="pick-find-polygon"
        >
          <path
            d="m145.998 3.928 109.058 62.965a24 24 0 0 1 12 20.785v125.93a24 24 0 0 1-12 20.784l-109.058 62.965a24 24 0 0 1-24 0L12.939 234.392a24 24 0 0 1-12-20.784V87.678a24 24 0 0 1 12-20.785L121.998 3.928a24 24 0 0 1 24 0z"
            fill="#FFF"
            fillRule="evenodd"
          />
        </svg>
        {/* End Polygons - Success - Error - Default */}

        <div className="pick-find-content">
          <input type="hidden" name="cevap2" />

          <div className="text-left px-4 text-sm flex flex-col flex-nowrap max-w-[200px]">
            {itemPro.brandStylePropertyList.map((itemPros, indexP) => (
              <p key={indexP}>
                <strong>{itemPros.propertyName} :</strong>{" "}
                {itemPros.propertyValue}
              </p>
            ))}

            <p>
              <strong>Fiyat :</strong> {itemPro.brandStylePrice}
            </p>
          </div>
        </div>

        {/* <div
          style={{
            pointerEvents: itemPro.selectedCard === 2 ? "none" : ""
          }}
          data-value={index2}
          key={index2}
          className={`soru-box games-box ${
            itemPro.selectedCard === 2
              ? "green"
              : itemPro.selectedCard === 3
              ? "red"
              : ""
          } properties d-flex align-items-center ${
            itemPro.selectedCard === 1
              ? "active"
              : itemPro.selectedCard === 0
              ? "dshadow"
              : ""
          }`}
          onClick={() => {
            selectedBottomControl(brandPropertyList, index2, brandNameList);
            choiceBottom.push(itemPro.brandStyleId);
            setSelectedBottom(choiceBottom);
            setSelectedBottomIndex(index2);
          }}
        >
          <input type="hidden" name="cevap2" />
          <div>
            {itemPro.brandStylePropertyList.map((itemPros, indexP) => (
              <p key={indexP}>
                <strong>{itemPros.propertyName} :</strong>{" "}
                {itemPros.propertyValue}
              </p>
            ))}
            <p>
              <strong>Fiyat :</strong> {itemPro.brandStylePrice}
            </p>
          </div>
        </div> */}
      </div>
    ));
    return brandStyleProperties;
  }

  if (pickFindList) {
    pickFindGameTop = pickFindList.map((item, index) => {
      return (
        <div
          className="flex gap-4 h-100 flex-col lg:flex-row wrapper-pick-find"
          key={index}
        >
          {showBrandName(item.brandStyleNames, item.brandStyleProperties)}
        </div>
      );
    });

    pickFindGameBottom = pickFindList.map((item, index) => {
      return (
        <div
          key={index}
          className="flex gap-4 h-100 flex-col lg:flex-row wrapper-pick-find wrapper-pick-find-bottom"
        >
          {showBrandPropery(item.brandStyleProperties, item.brandStyleNames)}
        </div>
      );
    });

    pickFindGameButton = pickFindList.map((item, index) => {
      return (
        <div
          key={index}
          className="w-full lg:w-[calc(100%-324px)] bg-white flex-1 flex justify-between ml-auto items-start rounded-[12px] flex-col mt-4 py-6 lg:px-8 px-4"
        >
          <div className="action flex justify-between w-full">
            <Link
              to="/userportal/pickFind"
              className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px]"
            >
              <IoArrowBack className="h-5 w-5" />
              Geri Git{" "}
            </Link>

            <button
              type="submit"
              className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[135px] min-w-[120px] max-h-[52px] rounded-full border-2 bg-[#00b185] border-[#00b185] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px] text-white"
              onClick={e => {
                compareCards(
                  selectedTop,
                  selectedBottom,
                  item.id,
                  employeeFirm.id,
                  employeeFirm.firm.id,
                  selectedTopIndex,
                  selectedBottomIndex,
                  item.brandStyleNames,
                  item.brandStyleProperties
                );
              }}
            >
              Doğrula <IoArrowForward className="h-5 w-5 text-white" />
            </button>
          </div>
        </div>
      );
    });
  }

  function compareCards(
    selectedTop,
    selectedBottom,
    pickFindId,
    employeeFirmId,
    firmId,
    selectedTopIndex,
    selectedBottomIndex,
    brandStyleNames,
    brandStyleProperties
  ) {
    if (selectedTop && selectedBottom) {
      if (selectedTop.length <= 0 || selectedBottom.length <= 0) {
        showErrMessage("Seçenek seçiniz.");
      }
    }

    for (let index = 0; index < brandStyleNames.length; index++) {
      for (let index2 = 0; index2 < brandStyleProperties.length; index2++) {
        if (
          brandStyleNames[index].selectedCard === 1 &&
          brandStyleProperties[index2].selectedCard === 1
        )
          if (
            selectedTop[0] === selectedBottom[0] &&
            brandStyleNames[selectedTopIndex].selectedCard !== 2 &&
            brandStyleProperties[selectedBottomIndex].selectedCard !== 2
          ) {
            brandStyleNames[selectedTopIndex].selectedCard = 2;
            brandStyleProperties[selectedBottomIndex].selectedCard = 2;

            if (pickFindList.length > 0) {
              setSelectedBottomIndex(null);
              setSelectedTopIndex(null);
            }
          } else {
            brandStyleNames[selectedTopIndex].selectedCard = 3;
            brandStyleProperties[selectedBottomIndex].selectedCard = 3;
            if (pickFindList.length > 0) {
              setSelectedBottomIndex(null);
              setSelectedTopIndex(null);
            }
          }
      }
    }
    var counterTop = 0;
    brandStyleNames.map(item => {
      if (item.selectedCard === 2) counterTop = counterTop + 1;
    });

    var counterBottom = 0;
    brandStyleProperties.map(item => {
      if (item.selectedCard === 2) counterBottom = counterBottom + 1;
    });

    if (counterTop === 3 && counterBottom === 3) {
      dispatch(
        pickFindAction.savePickFindAnswer(pickFindId, employeeFirmId, firmId)
      )
        .then(e => {
          dispatch(
            authAction.returnNewScoreOfEmployee(
              employeeFirm.firm.id,
              employeeFirm.employee.id
            )
          );
          setRedirectAnswer(true);
        })
        .catch(err => {
          showErrMessage("Seçenek seçiniz.");
        });
    }
  }

  if (redirectAnswer) {
    return <Redirect to="/userportal/pickFindAnswer" />;
  }

  return (
    <div>
      <GlobalLoader isLoading={isLoading} />

      {/* New Design */}
      <section className="bg-[#f8fafc]" id="pickFindAnswers">
        <div className="container mx-auto py-20 px-6 lg:px-0">
          <div className="flex gap-4 h-100 flex-col lg:flex-row">
            <div className="relative h-[333px] w-[308px] gap-20 bg-[url('assets/images/new-design/game/card-bg.png')] bg-left-bottom bg-no-repeat mx-auto">
              <h2 className="lg:text-[36px] text-[28px] text-center px-4 py-4 font-bold text-white">
                Eşle Kazan
              </h2>
              <img
                src={CardIcon}
                className="w-[220px] absolute bottom-0 left-1/2 -translate-x-1/2"
                alt=""
              />
            </div>
            <div className="bg-slate-200 flex-1 flex justify-center items-start rounded-[12px] flex-col py-12 px-12">
              <h3 className="mb-8 inline-flex items-center gap-4 text-2xl font-semibold text-slate-700">
                <FaInfoCircle className="h-6 w-6" /> Eşle Kazan Nasıl Oynanır?
              </h3>

              <p className="tracking-wide">
                Üst sıradaki JTI ürünlerini alt taraftaki ürün özellikleri ile
                tek tek eşleştirip her biri için Doğrula butonuna basınız. Her
                iki kutu da <span className="text-slate-700">yeşil</span> renkte
                ise ürün ile ürün özelliklerini doğru eşleştirmişsiniz demektir.
                Kutular <span className="text-slate-700">kırmızı</span> renkte
                ise ürün ile ürün özelliklerini yanlış eşleştirmişsiniz
                demektir. Farklı kutular seçip tekrar deneyin. Hepsini doğru
                eşleştirin ve <span className="text-slate-700">100 puan</span>{" "}
                kazanın.
              </p>
            </div>
          </div>

          <div className="w-full xl:w-[calc(100%-324px)] bg-slate-200 bg-no-repeat bg-cover flex-1 flex justify-between items-center ml-auto rounded-[12px] flex-col mt-4 py-6 lg:px-8 px-4">
            {pickFindGameTop}
            {pickFindGameBottom}
          </div>

          {pickFindGameButton}
        </div>
      </section>
      {/* End New Design */}
    </div>
  );
};
export default PickFindQuestion;
