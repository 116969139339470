import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { disclaimerAction, authAction } from "redux/modules";
import { Redirect, useHistory } from "react-router-dom";
import Modal from "react-responsive-modal";

import showMessage from "hooks/showMessage";
import showErrMessage from "hooks/showErrMessage";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";

const DisclaimerIys = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const disclaimerIys = useSelector(store => store.disclaimer.disclaimerIys);
  const isLoading = useSelector(store => store.disclaimer.isLoading);

  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(!open);
  const [redirect, setRedirect] = useState(false);
  const [iysShow, setIysShow] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState(null);

  const saveIys = e => {
    e.preventDefault();

    if (selectedChoice !== null && selectedChoice !== undefined) {
      dispatch(
        disclaimerAction.saveIysDisclaimer(employeeFirm.ercCode, selectedChoice)
      ).then(res => {
        if (res.isHttpSuccess) {
          showMessage("Iys tercihleriniz kaydedildi!");
          setIysShow(false);
          dispatch(
            disclaimerAction.getIysDisclaimerByErc(employeeFirm.ercCode)
          ).then(res => {
            if (res.isHttpSuccess) {
              dispatch(
                disclaimerAction.getDisclaimerPhotoByFirmId(employeeFirm.id)
              )
                .then(resPhoto => {
                  if (resPhoto.isHttpSuccess) {
                    if (resPhoto?.data?.length > 0) {
                      if ("confirmed" in resPhoto.data[0]) {
                        history.push("/");
                      } else {
                        history.push("/disclaimerPhoto");
                      }
                    } else {
                      history.push("/");
                    }
                  }
                })
                .catch(resPhotoError => {
                  if (resPhotoError) {
                    history.push("/");
                  }
                });
            }
          });
        }
      });
    } else {
      showErrMessage("Lütfen Bir Seçenek Seçiniz!");
    }
  };

  useEffect(() => {
    if (employeeFirm?.ercCode) {
      dispatch(
        disclaimerAction.getIysDisclaimerByErc(employeeFirm.ercCode)
      ).then(res => {
        if (res.isHttpSuccess) {
          if (res.data) {
            setIysShow(true);
          } else {
            setIysShow(false);
            setRedirect(true);
          }
        }
      });
    }
  }, [employeeFirm]);

  const bg = {
    overlay: {
      background: "#424242"
    }
  };

  if (redirect) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <div>
      <GlobalLoader isLoading={isLoading} />
      {iysShow && (
        <Modal
          open={!open}
          onClose={closeModal}
          role="dialog"
          closeOnEsc={false}
          showCloseIcon={false}
          closeOnOverlayClick={false}
          styles={bg}
          modalId="disclaimer-modal-area"
        >
          <div className="max-h-[800px] p-[25px] overflow-y-scroll">
            <div>
              {disclaimerIys && disclaimerIys?.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: disclaimerIys?.description
                  }}
                />
              )}

              <form
                className="d-flex flex-col justify-center align-center w-full mt-4"
                onSubmit={e => saveIys(e)}
              >
                <div className="text-center mb-3">
                  <label
                    className="mr-3 cursor-pointer"
                    onClick={() => setSelectedChoice(true)}
                  >
                    <input type="radio" name="iys" />{" "}
                    <span>İzin veriyorum</span>
                  </label>

                  <label
                    className="cursor-pointer"
                    onClick={() => setSelectedChoice(false)}
                  >
                    <input type="radio" name="iys" />{" "}
                    <span>İzin vermiyorum</span>
                  </label>
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-success text-white hover:opacity-70 rounded-[10px]"
                  >
                    Gönder
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default DisclaimerIys;
