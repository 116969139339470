import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  fallback,
  ...otherProps
}) => {
  const location = useLocation();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);

  return (
    <Route
      {...otherProps}
      render={props =>
        isAuthenticated ? (
          <>
            <Component {...props} />
            {/* Final Yarışmasında Açılacak */}
            {employeeFirm?.allowedUser === false &&
              location.pathname.startsWith("/competition") && (
                <Redirect
                  to={{
                    pathname: "/"
                  }}
                />
              )}
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
