import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileAction } from "redux/modules";
import showErrMessage from "hooks/showErrMessage";
import ProfileContactUs from "./ProfileContactUs";
import Resizer from "react-image-file-resizer";
import { imageShowUrl, documentShowUrl } from "../../../config/api.config";

import Moment from "react-moment";

const ProfileMessage = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const subjectList = useSelector(store => store.profile.subjectList);
  const messageList = useSelector(store => store.profile.messageList);
  const footer = useSelector(store => store.profile.footer);
  var messageSubject = null;
  var messageContent = null;
  const [messageSubjectSelected, setMessageSubjectSelected] = useState(null);
  const [showMessageList, setShowMessageList] = useState(false);
  const [remainingCharCount, setRemainingCharCount] = useState(1000);
  const [newMessageText, setNewMessageText] = useState("");
  const [messageListClosed, setMessageListClosed] = useState(null);
  const uploadInput = useRef();
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    dispatch(profileAction.getMessageSubjectsByEmployeeFirmId(employeeFirm.id));
    return () => {};
  }, []);

  function getMessagesOfSubject(item) {
    dispatch(profileAction.getContactMessagesBySubjectId(item.id))
      .then(e => {
        setShowMessageList(true);
        dispatch(profileAction.getNumberOfMessages(employeeFirm.id));
      })
      .catch(err => {});
  }

  function returnToSubjectList() {
    dispatch(profileAction.getMessageSubjectsByEmployeeFirmId(employeeFirm.id));
    setShowMessageList(false);
  }

  function wordCount(e) {
    var currentText = e.target.value;
    setNewMessageText(currentText);
    var characterCount = currentText.length;
    setRemainingCharCount(1000 - characterCount);
  }

  function checkEmptyText(e) {
    if (newMessageText.length <= 0) {
      showErrMessage("Mesaj Giriniz");
      e.preventDefault();
    } else {
      saveNewMessage(
        newMessageText,
        messageSubjectSelected.id,
        employeeFirm.id
      );
      setNewMessageText("");
      setRemainingCharCount(1000);
      setImgSrc(null);
    }
  }

  function saveNewMessage(message, contactSubjectId, senderEmployeeFirmId) {
    var splittedImgSrc = null;
    if (imgSrc != null) {
      var initialSplittedImgSrc = imgSrc.split(",");
      splittedImgSrc = initialSplittedImgSrc[1];
    }
    dispatch(
      profileAction.saveNewMessage(
        message,
        contactSubjectId,
        senderEmployeeFirmId,
        splittedImgSrc
      )
    );
    getMessagesOfSubject(messageSubjectSelected);
  }

  function fileChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      Resizer.imageFileResizer(
        event.target.files[0],
        620,
        465,
        "JPEG",
        100,
        0,
        uri => {
          setImgSrc(uri);
        },
        "base64"
      );
    }
  }

  if (subjectList) {
    messageSubject = subjectList.map(function(item, index) {
      return (
        <div
          key={index}
          className="message-item"
          style={{ cursor: "pointer" }}
          onClick={() => {
            getMessagesOfSubject(item);
            setMessageSubjectSelected(item);
            dispatch(profileAction.showContactUs(null));
          }}
        >
          {/* <form action=""> */}
          <div className="message-item-content">
            {/* <input type="checkbox" id="message-1" />
                  <label htmlFor="message-1"> */}

            <div className="message-item-title">
              <div className="message-item-flex">
                <div className="message-item-title-text">
                  {/*  {item.unreadMessage === true ? */}
                  <h2
                    style={{
                      fontWeight:
                        item.listMessage[item.listMessage.length - 1]
                          .unreadMessage === true
                          ? "bold"
                          : ""
                    }}
                  >
                    {item.subject}
                  </h2>
                  {/*  : <h2 >{item.subject}</h2> */}
                  {/* } */}
                  <span
                    style={{
                      fontWeight:
                        item.listMessage[item.listMessage.length - 1]
                          .unreadMessage === true
                          ? "bold"
                          : ""
                    }}
                  >
                    Kimden:
                    {item.sender
                      ? item.listMessage[item.listMessage.length - 1].sender
                          .employee.role === "user"
                        ? " " +
                          item.sender.employee.name +
                          " " +
                          item.sender.employee.surname
                        : " Benim Bayim"
                      : null}
                  </span>
                </div>
                <div className="date">
                  <Moment
                    format="DD-MM-YYYY HH:mm"
                    style={{
                      fontWeight:
                        item.listMessage[item.listMessage.length - 1]
                          .unreadMessage === true
                          ? "bold"
                          : ""
                    }}
                  >
                    {item.dateBegin}
                  </Moment>
                </div>
              </div>
            </div>
            {/* </label> */}
          </div>
          {/* </form> */}
        </div>
      );
    });
  }

  if (messageList) {
    messageContent = messageList.map(function(item, index) {
      // Dosyanın uzantısını alıyoruz
      const fileExtension = item.icon
        ? item.icon
            .split(".")
            .pop()
            .toLowerCase()
        : "";

      return (
        <div
          key={index}
          className={
            "past-message-item " +
            (item.sender && item.sender.employee.role === "user"
              ? "right-message"
              : "left-message")
          }
        >
          <div className="past-message-item-text">
            <p>{item.message}</p>
            {item.icon ? (
              // Eğer dosya bir resimse göster, PDF ise link olarak göster
              fileExtension === "png" ||
              fileExtension === "jpg" ||
              fileExtension === "jpeg" ? (
                <img
                  src={imageShowUrl + item.icon}
                  alt="image"
                  style={{ paddingTop: "5px" }}
                />
              ) : fileExtension === "pdf" ? (
                <a
                  href={documentShowUrl + item.icon}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PDF Dosyasını Görüntüle - {item.icon}
                </a>
              ) : null
            ) : null}
          </div>
          <span>
            <time dateTime="2019-05-22 10:45">{item.dateString}</time> Tarihinde{" "}
            {item.sender && item.sender.employee.role === "user"
              ? " " +
                (item.sender && item.sender.employee.name) +
                " " +
                (item.sender && item.sender.employee.surname)
              : " Benim Bayim"}{" "}
            Tarafından Gönderildi
          </span>
        </div>
      );
    });
  }

  return (
    <div
      className="tab-pane fade show active"
      id="message"
      role="tabpanel"
      aria-labelledby="message-tab"
    >
      <div className="user-content-item message">
        <div className="user-content-text">
          <p style={{ fontSize: "14px" }}>
            Sizi dinliyoruz. Her türlü görüş, öneri ve isteklerinizi Bize Ulaşın
            bölümü aracılığıyla bizimle paylaşabilirsiniz. Dilerseniz 0850 532 5
            584 (JTI) numaralı Çağrı Merkezi’mizi arayabilir, bayi kodunuzu ve
            Satış Belgesi numaranızı kullanarak müşteri temsilcimizle
            görüşebilirsiniz. Bayi kodunuzu bilmiyorsanız JTI satış
            temsilcinizden öğrenebilirsiniz.
            <br /> <br />
            Benim Bayim ile ilgili sorularınız için 0850 532 5 584 (JTI)
            numaralı Çağrı Merkezimizi arayarak hafta içi 09.00-18.00 saatleri
            arasında bize ulaşabilirsiniz.
          </p>
        </div>

        {showMessageList ? null : (
          <div className="message-tab-menu">
            <ul className="nav" role="tablist">
              <div className="all-select-message-container">
                {/* <div
                        className="all-select-message active"
                        data-id="1"
                      ></div>
                      <div className="all-select-message" data-id="2"></div> */}
              </div>

              <li>
                <a
                  className={`${footer && !messageListClosed ? "" : "active"}`}
                  id="new-message-tab"
                  data-id="1"
                  data-toggle="tab"
                  href="#new-message"
                  role="tab"
                  aria-controls="new-message"
                  aria-selected="true"
                >
                  Tüm Mesajlar
                </a>
              </li>
              {/* <li className="nav-item">
                      <a
                        id="trash-message-tab"
                        data-id="2"
                        data-toggle="tab"
                        href="#trash-message"
                        role="tab"
                        aria-controls="trash-message"
                        aria-selected="false"
                      >
                        Çöp Kutusu
                      </a>
                    </li> */}
              <li className="nav-item">
                <a
                  className={`${footer && !messageListClosed ? "active" : ""}`}
                  id="contact-message-tab"
                  data-toggle="tab"
                  href="#contact-message"
                  role="tab"
                  aria-controls="contact-message"
                  aria-selected="false"
                >
                  Bize Ulaşın
                </a>
              </li>
            </ul>
            {/* <div className="delete-message">
                    <span>Sil</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="31"
                      viewBox="0 0 26 31"
                    >
                      <g fill="#111111">
                        <image
                          ata-name="Vector Smart Object"
                          width="26"
                          height="31"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAfCAYAAAD5h919AAABuUlEQVRIie2WTStEURjHf+NdFliLZIqxQk5sREkhKUkWU7OwmazsfA++gM2pU5SNLAhF2eAmG0ulJAsrFuP9pTOda07nMK6Ysphf3br3Oc8z/7nneU73H1uPt5KHYWABSHydkuUJOABmg1Ty1FsFSrxIjjZgLYKIphzoAzaFVFXeKlDmRXJMABXmaRu49DJydAIdQAPQC+z9RKjaup8HTrwMIEglEVLNAEsmVO8lAbpH08CU9e9DEmb7NLvAjVedownoMk+HwJW1pvu3qoUezR4Xkns9DMvAcwFFXoCV78b7W3SPopBvvCMhpCr9iVANsGUOXaOTo8f1GFj0qmEHyAipJu2gkCoupDoTUgVCqlpbqB8YAnqAcefH0mai5oBaK14HDJppdWtGgRag2xzkD6FKK8kdc3trYt47fR63z2c5f9GjqBSFikJFoaKQz78SevMiBRLaMJ/jA8eg3AL7Zm3Dq3LIZ7dClo2RvHPir8AAUBWkkhmvyiFqj1yRD7EoIlhvdG3F0sZ5RkJI9Vma/vyHZD1eKHQEnAPNQLu5/oKL0OGGW6dN5JjxzNpZ/hY9qdq4jASp5APAO5uRWKtb+CqQAAAAAElFTkSuQmCC"
                        ></image>
                      </g>
                    </svg>
                  </div> */}
          </div>
        )}

        <div className="tab-content">
          <div
            className={`tab-pane fade ${footer ? "" : " show active"}`}
            id="new-message"
            role="tabpanel"
            aria-labelledby="new-message-tab"
          >
            {showMessageList ? null : (
              <div className="message-item-container">{messageSubject}</div>
            )}
          </div>
          {/* <div
                    className="tab-pane fade"
                    id="trash-message"
                    role="tabpanel"
                    aria-labelledby="trash-message-tab"
                  >
                    <div className="message-item-container">
                      <div className="message-item" data-id="4">
                        <form action="">
                          <div className="message-item-content">
                            <input type="checkbox" id="message-1" />
                            <label htmlFor="message-1">
                              <div className="message-item-title">
                                <div className="check-box"></div>
                                <div className="message-item-flex">
                                  <div className="message-item-title-text">
                                    <h2>
                                      Camel Oyunu 25 Kasım Pazartesi başlıyor!
                                    </h2>
                                    <span>Kimden: Benim Bayim</span>
                                  </div>
                                  <div className="date">
                                    <time dateTime="2019-11-20">
                                      20.11.2019
                                    </time>
                                  </div>
                                </div>
                              </div>
                            </label>
                          </div>
                        </form>
                      </div>
                      <div className="message-item" data-id="4">
                        <form action="">
                          <div className="message-item-content">
                            <input type="checkbox" id="message-2" />
                            <label htmlFor="message-2">
                              <div className="message-item-title">
                                <div className="check-box"></div>
                                <div className="message-item-flex">
                                  <div className="message-item-title-text">
                                    <h2>
                                      JTI 2019 Dünya Kupası heyecanı Winston
                                      Oyunu ile devam ediyor!
                                    </h2>
                                    <span>Kimden: Benim Bayim</span>
                                  </div>
                                  <div className="date">
                                    <time dateTime="2019-11-20">
                                      20.11.2019
                                    </time>
                                  </div>
                                </div>
                              </div>
                            </label>
                          </div>
                        </form>
                      </div>
                      <div className="message-item" data-id="4">
                        <form action="">
                          <div className="message-item-content">
                            <input type="checkbox" id="message-3" />
                            <label htmlFor="message-3">
                              <div className="message-item-title">
                                <div className="check-box"></div>
                                <div className="message-item-flex">
                                  <div className="message-item-title-text">
                                    <h2>
                                      Camel Oyunu 25 Kasım Pazartesi başlıyor!
                                    </h2>
                                    <span>Kimden: Benim Bayim</span>
                                  </div>
                                  <div className="date">
                                    <time dateTime="2019-11-20">
                                      20.11.2019
                                    </time>
                                  </div>
                                </div>
                              </div>
                            </label>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div> */}
          <ProfileContactUs />
        </div>

        {showMessageList ? (
          <div className="past-message-container" style={{ display: "block" }}>
            <div className="past-message-title">
              <h2>MESAJ GEÇMİŞİ</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                onClick={() => {
                  setMessageListClosed("closed");
                  returnToSubjectList();
                }}
              >
                <g fill="#111111">
                  <image
                    data-name="Vector Smart Object copy 25"
                    width="25"
                    height="25"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAABaUlEQVRIiaXWu0oDQRiG4TdLKkFEBkvvwloCFl6EbYpor8liQCxCNpXFilpYeRd2gncjDqnUUhkzC+Mcdg5+Tdh/Dg//ZnfYQdu2I+AJ2ANugEvgm8RIKf9MFEIMgCvgHFgDJ0PgAdjXc2pgFzjLgSzgDpjo0hbwWAE71tyJnjhwdonn1gC6bCtk4VmaDQkhGn0H7FxXWq+doQxIA1NnAGop5X2lL5pSKAKoMSqjWAJFAZWhZ5HK0gOpnOYCdifm4lBHjTEnCcDTiQnh6UhtfAQcOCsCAIFOTMjXkQ9YhIAY0geZWUkp5041A+mgZ6e6yYuUcuZUC5Fjp7rJSL8nvUm5Xb6nyMw0BvUhIeDVqUSgEBICVsCh/k2GfEgf0P3JsxzIRlKALsmQfUCmAlmQedTnAr/R70kvpM6ui1LAhIQQePZR0LrSXxbFgAkFOpor5Ou/QAT6VMgYeNeYOuiKAAtS324fwBsw/gFQSIZ8FHj0cQAAAABJRU5ErkJggg=="
                  ></image>
                </g>
              </svg>
            </div>
            <div className="past-message-item-container">{messageContent}</div>
            <form
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              {imgSrc ? (
                <div className="form-el">
                  <img
                    src={imgSrc}
                    alt=""
                    style={{
                      height: "240px",
                      width: "320px",
                      paddingBottom: "10px",
                      paddingTop: "5px"
                    }}
                  ></img>
                </div>
              ) : null}
              <div className="form-el">
                {messageSubjectSelected &&
                messageSubjectSelected.contactStatu === "OPEN" ? (
                  <textarea
                    id="newmessagetext"
                    placeholder="Yeni Mesaj"
                    className="form-control"
                    cols="4"
                    rows="4"
                    maxLength="1000"
                    value={newMessageText}
                    onChange={wordCount}
                  ></textarea>
                ) : null}

                {messageSubjectSelected &&
                messageSubjectSelected.contactStatu === "CLOSED" ? null : (
                  <div className="add-files">
                    <input
                      type="file"
                      ref={uploadInput}
                      style={{ display: "none" }}
                      onChange={fileChangedHandler}
                    />
                    <span>Dosya/Fotoğraf Ekle</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                    >
                      <g fill="#111111">
                        <image
                          style={{ cursor: "pointer" }}
                          onClick={() => uploadInput.current.click()}
                          data-name="Vector Smart Object"
                          width="28"
                          height="28"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAADFklEQVRIia2WSWxNURzGf31tVaSkBJeQsKBioa+JISSGhCJhRQyJYSMhwdawQHISLAxbNtjRxBQ2JCpszAviEsSUEEQeC6UJbdWQv/fd9vSe957SfsnNu+ec//m+d8/5T2Wc28hfUAEsABYBM4BxwCBt+QI8B24DF4FLLo5+lKIrJVgJbAK2AyOD1cJ4BxwADrs4+l7IophgPdAITPTmXgGXgUfAB80NByYB84Cxnu1jYLWLo/s9EVwGHAeqNDaR3cB14Ffa2MURLpsrA2YCO4CFWmqT6NlSgiZ2EsgAzcB64Ezw/SnBbuNsbglwDBgM/ARW+KIZz7ZeX5bRXUwvITZFTwAXR+eAaboC4zrhsrn6tGCl7qxKXzYXeBqw5dFPx3sLqA5W86IvgPnAJ3E2umyu0hfc5DnIBuBZwNKFASKxcBkarHYXXafhRGn8ESwHtmrBHOR0sPs/4eLovMWmdm9x2Vx5Rl41SpN7+krMw169jrYEklEGQZd8LTDvPa6L27A4I280XCkUZ72FiyPjvCqa6Xbx4zV4kOK2fDmkgF5/732Ey+YswL+lbNpdHH31xgn3hAovEX/wDKYCNxQupXCryFqHy+bmuDi6qfFH/Vb7gV/mvVvG7whoeg7b7yfvzquyL2wBBioRJ7gH1Cjm0rATea25OuBNYAGtLo5avfEw/X6pUJBPVtb30a6nFFpcHDWXWE+QcL/MqHgaGlLH2idQJWkQ100TvKDBGGB2XwuqbCW18qIJNgFvNbEzMO89donhvWll5FEHNdmgmthXx7lUVcOwz8VRRxIWh9UWGI5YgAa7u2BB3qo/+ilY7RKzhHJUwyfS6CxPFjOr1RbUKM3VBix5tOleZgGfg9W8WK3S2WDZr0qaKj/wreFZo7bAqscdYHnAlsfdYlnGZXPLtHe0uNb6zVRFyt5aipVqNexLT6lG7lElKZjc5fqz5HTJnbVJrFt9LdS1UaJNtKN+mGoT69QmjvFs7c7sGHvUJiawxL0Z2PYPjbC5/n7g0L82wj6sBbGuwAq11U7zvmKtfpO5flEm4DcDNdc7aF0K4wAAAABJRU5ErkJggg=="
                        ></image>
                      </g>
                    </svg>
                  </div>
                )}
              </div>
              <div className="count-submit">
                {messageSubjectSelected &&
                messageSubjectSelected.contactStatu === "OPEN" ? (
                  <span id="chars">{remainingCharCount} karakter kaldı</span>
                ) : null}
                {messageSubjectSelected &&
                messageSubjectSelected.contactStatu === "OPEN" ? (
                  <button type="submit" onClick={checkEmptyText}>
                    GÖNDER
                  </button>
                ) : null}
                {messageSubjectSelected &&
                messageSubjectSelected.contactStatu === "CLOSED" ? (
                  <span>
                    Bu mesajınızın kapatılmıştır. Yeni bir mesaj başlatarak bize
                    ulaşabilirsiniz.
                  </span>
                ) : null}
                {messageSubjectSelected &&
                messageSubjectSelected.contactStatu === "CLOSED" ? (
                  <button
                    type="submit"
                    onClick={() => {
                      setMessageListClosed(null);
                      setShowMessageList(false);
                      dispatch(profileAction.showContactUs("show"));
                    }}
                  >
                    YENİ MESAJ
                  </button>
                ) : null}
              </div>
            </form>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProfileMessage;
