import * as searchFindTypes from "./searchFind.type";
import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getControlSearchFindByFirmId = (
  brandId,
  employeeId
) => async dispatch => {
  dispatch({ type: searchFindTypes.GET_AVAILABLESEARCHFIND_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/searchFind/${brandId}/${employeeId}`,
      config
    );
    dispatch({
      type: searchFindTypes.GET_AVAILABLESEARCHFIND_BY_FIRMID_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: searchFindTypes.GET_AVAILABLESEARCHFIND_BY_FIRMID_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

// Question Page
export const getSearchFindByBrandId = (
  brandId,
  employeeId,
  questionId
) => async dispatch => {
  dispatch({ type: searchFindTypes.GET_SEARCHFINDLIST_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/searchFindQuestion/${brandId}/${employeeId}/${questionId}`,
      config
    );
    dispatch({
      type: searchFindTypes.GET_SEARCHFINDLIST_BY_FIRMID_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: searchFindTypes.GET_SEARCHFINDLIST_BY_FIRMID_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const saveFindQuestionAnswer = (
  question,
  questionChoice,
  employeeFirmId,
  firmId,
  brandId
) => async dispatch => {
  dispatch({ type: searchFindTypes.SAVE_SEARCHFIND_ANSWER_PENDING });

  const data = { question, questionChoice, employeeFirmId, brandId };
  try {
    const response = await API.post(`/searchFind/save`, data);
    const interaction = {
      message: " ",
      type: "notification"
    };

    dispatch({
      type: searchFindTypes.SAVE_SEARCHFIND_ANSWER_FULFILLED,
      payload: response,
      interaction
    });

    dispatch({
      type: searchFindTypes.SELECTED_OPTION,
      payload: null
    });

    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: searchFindTypes.SAVE_SEARCHFIND_ANSWER_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getResultSearchFind = (
  brandId,
  employeeId,
  questionId
) => async dispatch => {
  dispatch({ type: searchFindTypes.GET_RESULT_SEARCHFIND_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/searchFindAnswer/${brandId}/${employeeId}/${questionId}`,
      config
    );
    dispatch({
      type: searchFindTypes.GET_RESULT_SEARCHFIND_BY_FIRMID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: searchFindTypes.GET_RESULT_SEARCHFIND_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const changeOption = selectedOption => async dispatch => {
  dispatch({ type: searchFindTypes.SELECTED_OPTION, payload: selectedOption });
};

export const getBrandBadges = employeeFirmId => async dispatch => {
  dispatch({ type: searchFindTypes.GET_BRAND_BADGES_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/brandBadge/${employeeFirmId}`,
      config
    );
    dispatch({
      type: searchFindTypes.GET_BRAND_BADGES_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: searchFindTypes.GET_BRAND_BADGES_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getAllBadges = (employeeFirmId, brandId) => async dispatch => {
  dispatch({ type: searchFindTypes.GET_ALL_GAME_BADGES_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/getAllGameBadges/${employeeFirmId}/${brandId}`,
      config
    );
    dispatch({
      type: searchFindTypes.GET_ALL_GAME_BADGES_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: searchFindTypes.GET_ALL_GAME_BADGES_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const getAllEmployeeBadges = (
  employeeFirmId,
  brandId
) => async dispatch => {
  dispatch({ type: searchFindTypes.GET_ALL_BY_EMP_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/getAllByEmp/${employeeFirmId}`,
      config
    );
    dispatch({
      type: searchFindTypes.GET_ALL_BY_EMP_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: searchFindTypes.GET_ALL_BY_EMP_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};
