import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchFindAction, brandStyleAction, brandAction } from "redux/modules";
import { Link, Redirect } from "react-router-dom";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";

import { imageShowUrl } from "../../../config/api.config";

import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { BsFillCheckCircleFill } from "react-icons/bs";

import CardIconGreen from "assets/images/new-design/game/card-icon-green.png";

// Passive Badges
import WinstonDiamond from "assets/images/new-design/badge-passives/winston/winston-diamond.png";
import WinstonPlatinium from "assets/images/new-design/badge-passives/winston/winston-platinium.png";
import WinstonGold from "assets/images/new-design/badge-passives/winston/winston-gold.png";
import WinstonSilver from "assets/images/new-design/badge-passives/winston/winston-silver.png";
import WinstonBronze from "assets/images/new-design/badge-passives/winston/winston-bronze.png";

import CamelDiamond from "assets/images/new-design/badge-passives/camel/camel-diamond.png";
import CamelPlatinium from "assets/images/new-design/badge-passives/camel/camel-platinium.png";
import CamelGold from "assets/images/new-design/badge-passives/camel/camel-gold.png";
import CamelSilver from "assets/images/new-design/badge-passives/camel/camel-silver.png";
import CamelBronze from "assets/images/new-design/badge-passives/camel/camel-bronze.png";

import MonteCarloDiamond from "assets/images/new-design/badge-passives/monte-carlo/monte-carlo-diamond.png";
import MonteCarloPlatinium from "assets/images/new-design/badge-passives/monte-carlo/monte-carlo-platinium.png";
import MonteCarloGold from "assets/images/new-design/badge-passives/monte-carlo/monte-carlo-gold.png";
import MonteCarloSilver from "assets/images/new-design/badge-passives/monte-carlo/monte-carlo-silver.png";
import MonteCarloBronze from "assets/images/new-design/badge-passives/monte-carlo/monte-carlo-bronze.png";

import LdDiamond from "assets/images/new-design/badge-passives/ld/ld-diamond.png";
import LdPlatinium from "assets/images/new-design/badge-passives/ld/ld-platinium.png";
import LdGold from "assets/images/new-design/badge-passives/ld/ld-gold.png";
import LdSilver from "assets/images/new-design/badge-passives/ld/ld-silver.png";
import LdBronze from "assets/images/new-design/badge-passives/ld/ld-bronze.png";

const SearchFindStart = () => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const controlSearchFind = useSelector(
    store => store.searchFind.controlSearchFind
  );
  const isLoading = useSelector(store => store.searchFind.isLoading);
  const brandBadges = useSelector(store => store.searchFind.brandBadges);
  const allBadges = useSelector(store => store.searchFind.allBadges);
  const [selectedItem, setSelectedItem] = useState(
    JSON.parse(sessionStorage.getItem("selectedItem"))
  );
  const [nextBadge, setNextBadge] = useState({});
  const [selectedBrandQuestionId, setSelectedBrandQuestionId] = useState(null);

  useEffect(() => {
    dispatch(searchFindAction.getBrandBadges(employeeFirm.id));

    if (sessionStorage.getItem("selectedItem")) {
      setSelectedItem(JSON.parse(sessionStorage.getItem("selectedItem")));
    }

    if (sessionStorage.getItem("selectedBrandQuestionId")) {
      setSelectedBrandQuestionId(
        parseInt(sessionStorage.getItem("selectedBrandQuestionId"))
      );
    }

    return () => {};
  }, [dispatch, employeeFirm.id]);

  useEffect(() => {
    if (selectedItem && selectedItem.id) {
      dispatch(searchFindAction.getAllBadges(employeeFirm.id, selectedItem.id));
    }

    return () => {};
  }, [dispatch, employeeFirm.id, selectedItem]);

  useEffect(() => {
    if (allBadges) {
      for (const badge of allBadges) {
        if (badge.hasBadge === false) {
          setNextBadge(badge);
          sessionStorage.setItem("nextBadge", JSON.stringify(badge));
          break;
        }
      }
    }

    return () => {};
  }, [allBadges]);

  useEffect(() => {
    dispatch(
      searchFindAction.getControlSearchFindByFirmId(
        selectedItem.id,
        employeeFirm.id
      )
    ).then(e => {
      if (e.isHttpSuccess) {
        sessionStorage.setItem("questionId", e.data[0].questionId);
      }
    });
    dispatch(brandAction.getBrandsByFirmId(employeeFirm.firm.id));
    return () => {};
  }, [dispatch, employeeFirm.firm.id, employeeFirm.id, selectedItem]);

  return (
    <main className="bg-slate-50 pb-20">
      <GlobalLoader isLoading={isLoading} />

      <div className="mx-auto px-4 pt-4 xl:container lg:pt-20">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-4 mb-[16px]">
          <div className="col-span-4 flex flex-col justify-center bg-white px-[16px] pt-[34px] pb-[24px] text-center md:col-span-2 md:col-start-2 lg:col-span-1 rounded-[12px]">
            <img
              src={imageShowUrl + selectedItem.icon}
              className="mx-auto max-h-[66px] lg:max-h-[66px]"
              style={
                selectedItem.id === 149467
                  ? { height: "66px" }
                  : selectedItem.id === 149466
                  ? { height: "58px" }
                  : {}
              }
            />

            <div className="flex items-center justify-between mt-[48px]">
              <span className="text-primary-500 font-semibold text-[16px]">
                Rozet Durumu
              </span>
              <span className="text-primary-500 font-semibold text-[16px]">
                {selectedItem.employeeBadge}/{selectedItem.totalBadge}
              </span>
            </div>
            <div className="mt-3 h-2 w-full overflow-hidden rounded-lg bg-slate-200">
              <div
                className="h-full rounded-lg bg-primary-500"
                style={{
                  width:
                    (
                      (selectedItem.employeeBadge * 100) /
                      selectedItem.totalBadge
                    ).toFixed(2) + "%"
                }}
              ></div>
            </div>
          </div>
          <div className="relative col-span-4 bg-white flex md:flex-row flex-col justify-between items-center py-[40px] px-[48px] lg:col-span-3 rounded-[12px]">
            {selectedItem && selectedItem.id === 149464 && (
              <p className="md:text-[32px] text-[24px] text-[#334155] font-semibold max-w-[100%] lg:max-w-[260px]">
                Rozet Macerası <br className="hidden md:inline-block" />
                Winston
              </p>
            )}

            {selectedItem && selectedItem.id === 149465 && (
              <p className="md:text-[32px] text-[24px] text-[#334155] font-semibold max-w-[100%] lg:max-w-[260px]">
                Rozet Macerası <br className="hidden md:inline-block" />
                Camel
              </p>
            )}

            {selectedItem && selectedItem.id === 149466 && (
              <p className="md:text-[32px] text-[24px] text-[#334155] font-semibold max-w-[100%] lg:max-w-[260px]">
                Rozet Macerası <br className="hidden md:inline-block" />
                LD
              </p>
            )}

            {selectedItem && selectedItem.id === 149467 && (
              <p className="md:text-[32px] text-[24px] text-[#334155] font-semibold max-w-[100%] lg:max-w-[260px]">
                Rozet Macerası <br className="hidden md:inline-block" />
                Monte Carlo
              </p>
            )}

            {selectedItem.employeeBadge !== selectedItem.totalBadge && (
              <div className="flex items-center">
                <p className="md:text-[16px] text-[14px] text-[#64748b] font-normal pr-4">
                  Sıradaki Rozet:
                </p>
                <img
                  src={imageShowUrl + nextBadge.icon}
                  className="mx-auto max-h-[70px] lg:max-h-[125px]"
                  alt=""
                />
              </div>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
          <div className="col-span-4 flex flex-col max-h-[270px] md:max-h-[333px] h-[100%] md:h-[333px] justify-center rounded-xl bg-[url('assets/images/new-design/game/card-bg-green.png')] bg-no-repeat mx-auto w-full bg-cover p-6 text-center md:col-span-2 md:col-start-2 lg:col-span-1 lg:rounded-bl-[100px] rounded-bl-[22px]">
            <h1 className="mb-4 text-2xl font-bold text-white md:mb-6 lg:text-4xl">
              Rozet Macerası
            </h1>
            <img
              src={CardIconGreen}
              className="mx-auto max-h-[120px] lg:max-h-[200px]"
              alt=""
            />
          </div>

          <div className="col-span-4 lg:col-span-3 lg:col-start-2">
            <div className="grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-4">
              {allBadges &&
                allBadges.length > 0 &&
                allBadges.map((item, index) => {
                  return (
                    <div
                      key={index}
                      href="#"
                      className="rounded-xl bg-white pb-[22px]"
                    >
                      <div className="bg-[#e2e8f0] flex flex-col px-[12px] pt-[16px] pb-[45px] md:px-[24px] md:pt-[24px] rounded-xl relative rounded-b-none">
                        <p className="text-[14px] text-[#64748b] pb-1">
                          {item.badgeName}
                        </p>
                        <p className="text-[14px] md:text-[16px] text-[#334155] font-semibold max-w-[100%] lg:max-w-[250px]">
                          {item.description}
                        </p>
                        <div className="absolute bottom-0 left-1/2 flex w-103 -translate-x-1/2 translate-y-1/2 transform items-center justify-center rounded-full">
                          {item.hasBadge === false ? (
                            <>
                              {item.badgeName &&
                              item.badgeName.search("Elmas Winston") > -1 ? (
                                <img
                                  src={WinstonDiamond}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : item.badgeName.search("Platin Winston") >
                                -1 ? (
                                <img
                                  src={WinstonPlatinium}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : item.badgeName.search("Altın Winston") >
                                -1 ? (
                                <img
                                  src={WinstonGold}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : item.badgeName.search("Gümüş Winston") >
                                -1 ? (
                                <img
                                  src={WinstonSilver}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : item.badgeName.search("Bronz Winston") >
                                -1 ? (
                                <img
                                  src={WinstonBronze}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : (
                                ""
                              )}

                              {item.badgeName &&
                              item.badgeName.search("Elmas Camel") > -1 ? (
                                <img
                                  src={CamelDiamond}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : item.badgeName.search("Platin Camel") > -1 ? (
                                <img
                                  src={CamelPlatinium}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : item.badgeName.search("Altın Camel") > -1 ? (
                                <img
                                  src={CamelGold}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : item.badgeName.search("Gümüş Camel") > -1 ? (
                                <img
                                  src={CamelSilver}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : item.badgeName.search("Bronz Camel") > -1 ? (
                                <img
                                  src={CamelBronze}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : (
                                ""
                              )}

                              {item.badgeName &&
                              item.badgeName.search("Elmas Monte Carlo") >
                                -1 ? (
                                <img
                                  src={MonteCarloDiamond}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : item.badgeName.search("Platin Monte Carlo") >
                                -1 ? (
                                <img
                                  src={MonteCarloPlatinium}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : item.badgeName.search("Altın Monte Carlo") >
                                -1 ? (
                                <img
                                  src={MonteCarloGold}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : item.badgeName.search("Gümüş Monte Carlo") >
                                -1 ? (
                                <img
                                  src={MonteCarloSilver}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : item.badgeName.search("Bronz Monte Carlo") >
                                -1 ? (
                                <img
                                  src={MonteCarloBronze}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : (
                                ""
                              )}

                              {item.badgeName &&
                              item.badgeName.search("Elmas LD") > -1 ? (
                                <img
                                  src={LdDiamond}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : item.badgeName.search("Platin LD") > -1 ? (
                                <img
                                  src={LdPlatinium}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : item.badgeName.search("Altın LD") > -1 ? (
                                <img
                                  src={LdGold}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : item.badgeName.search("Gümüş LD") > -1 ? (
                                <img
                                  src={LdSilver}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : item.badgeName.search("Bronz LD") > -1 ? (
                                <img
                                  src={LdBronze}
                                  className={
                                    "mx-auto max-h-[70px] lg:max-h-[100px]"
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <img
                              src={imageShowUrl + item.icon}
                              className={
                                "mx-auto max-h-[70px] lg:max-h-[100px]"
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="flex items-center justify-between px-[16px] md:px-[24px] pt-[62px] pb-[0]">
                        <span className="text-primary-500 text-[16px] font-semibold mb-2">
                          {item.answeredQuestion}/{(index + 1) * 6} Doğru cevap
                        </span>
                        <BsFillCheckCircleFill className="text-[#00b185]" />
                      </div>
                      <div className="mt-3 h-2 w-[90%] overflow-hidden rounded-lg bg-slate-200 m-auto">
                        <div
                          className="h-full rounded-lg bg-primary-500"
                          style={{
                            width:
                              (item.answeredQuestion * 100) /
                                ((index + 1) * 6) +
                              "%"
                          }}
                        ></div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="col-span-4 lg:col-span-3 lg:col-start-2">
            <div className="grid grid-cols-1 gap-2  md:gap-4">
              <div className=" bg-white w-full flex-1 flex justify-between ml-auto items-start rounded-[12px] flex-col mt-8 py-6 px-6">
                {/* <Link
                        to={{
                          pathname: "/userportal/searchFindQuestion"
                        }}
                        className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[135px] min-w-[120px] max-h-[52px] rounded-full border-2 bg-[#00b185] border-[#00b185] py-[16px] pl-[24px] pr-[24px] md:pr-[36px] text-white"
                      >
                        Başla
                        <IoArrowForward />
                      </Link> */}

                {controlSearchFind &&
                  controlSearchFind.length > 0 &&
                  controlSearchFind.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="action flex justify-between w-full"
                      >
                        {item.availableTodayQuestion === true &&
                          item.availableTodayQuestion === true &&
                          item.answerable === false && (
                            <Link
                              to={{
                                pathname: "/userportal/searchFind"
                              }}
                              className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] py-[16px] pl-[24px] pr-[24px] md:pr-[36px]"
                            >
                              <IoArrowBack />
                              Geri Git
                            </Link>
                          )}

                        {item.availableTodayQuestion === true &&
                          item.answerable === true && (
                            <>
                              {!selectedBrandQuestionId && (
                                <>
                                  <Link
                                    to={{
                                      pathname: "/userportal/searchFind"
                                    }}
                                    className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] py-[16px] pl-[24px] pr-[24px] md:pr-[36px]"
                                  >
                                    <IoArrowBack />
                                    Geri Git
                                  </Link>

                                  <Link
                                    to={{
                                      pathname: "/userportal/searchFindQuestion"
                                    }}
                                    className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[135px] min-w-[120px] max-h-[52px] rounded-full border-2 bg-[#00b185] border-[#00b185] py-[16px] pl-[24px] pr-[24px] md:pr-[36px] text-white"
                                  >
                                    Başla
                                    <IoArrowForward />
                                  </Link>
                                </>
                              )}

                              {selectedBrandQuestionId === selectedItem.id && (
                                <>
                                  <Link
                                    to={{
                                      pathname: "/userportal/searchFind"
                                    }}
                                    className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] py-[16px] pl-[24px] pr-[24px] md:pr-[36px]"
                                  >
                                    <IoArrowBack />
                                    Geri Git
                                  </Link>

                                  <Redirect
                                    to={{
                                      pathname: "/userportal/searchFindQuestion"
                                    }}
                                  />
                                </>
                              )}
                            </>
                          )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default SearchFindStart;
