import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameAction } from "redux/modules";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import tr from "date-fns/locale/tr"; // the locale you want

setDefaultLocale("tr");
registerLocale("tr", tr); // register it with the name you want

const Winner = () => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const winnerList = useSelector(store => store.game.winnerList);
  const isLoading = useSelector(store => store.game.isLoading);
  const [disclaimerList, setDisclaimerList] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchNameResults, setSearchNameResults] = useState([]);
  const [resetFilter, setResetFilter] = useState(true);
  const [loaded, setLoaded] = useState(false);

  const [date, setDate] = useState(null);

  useEffect(() => {
    if (!disclaimerList || disclaimerList.length === 0) {
      dispatch(
        gameAction.getCompetitionDisclaimerByFirmId(
          employeeFirm.firm.id,
          employeeFirm.id
        )
      ).then(response => {
        setLoaded(true);
        setDisclaimerList(response.data);
      });
    }

    return () => {};
  });

  useEffect(() => {
    dispatch(gameAction.getWinnerByFirmId(employeeFirm.firm.id)).then(
      response => {
        setLoaded(true);
      }
    );
    return () => {};
  }, []);

  function setSearchNames(e) {
    var currentText = e.target.value;
    setSearchName(currentText);
  }

  function setSearchDates(e) {
    setDate(e);
  }

  function search() {
    // item.employeeFirm.vendorName;
    // item.winnerDate;
    // item.employeeFirm.vendorName == searchName

    if (date && searchName) {
      setResetFilter(false);
      let list = [];
      let todayTime = new Date(date);
      let month = todayTime.getMonth() + 1;
      month = month >= 10 ? month : "0" + month;
      let day = todayTime.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      let year = todayTime.getFullYear();
      let currentDate = day + "-" + month + "-" + year;

      list = global._.filter(winnerList, function(item) {
        return (
          item.winnerDate == currentDate &&
          replaceTr(item.employeeFirmDTO.vendorName).indexOf(
            replaceTr(searchName)
          ) > -1
        );
      });
      setSearchNameResults(list);
    } else if (date) {
      setResetFilter(false);
      let list = [];
      let todayTime = new Date(date);
      let month = todayTime.getMonth() + 1;
      month = month >= 10 ? month : "0" + month;
      let day = todayTime.getDate();

      if (day < 10) {
        day = "0" + day;
      }

      let year = todayTime.getFullYear();
      let currentDate = day + "-" + month + "-" + year;

      list = global._.filter(winnerList, function(item) {
        return item.winnerDate == currentDate;
      });
      setSearchNameResults(list);
    } else if (searchName) {
      setResetFilter(false);
      let list = [];
      list = global._.filter(winnerList, function(item) {
        return (
          replaceTr(item.employeeFirmDTO.vendorName).indexOf(
            replaceTr(searchName)
          ) > -1
        );
      });
      setSearchNameResults(list);
      setLoaded(true);
    } else {
      setResetFilter(true);
    }
  }

  function replaceTr(text) {
    if (!text) {
      return "";
    }
    var trMap = {
      çÇ: "c",
      ğĞ: "g",
      şŞ: "s",
      üÜ: "u",
      ıİ: "i",
      öÖ: "o"
    };
    for (var key in trMap) {
      text = text.replace(new RegExp("[" + key + "]", "g"), trMap[key]);
    }
    return text
      .replace(/[^-a-zA-Z0-9\s]+/gi, "") // remove non-alphanumeric chars
      .replace(/\s/gi, "-") // convert spaces to dashes
      .replace(/[-]+/gi, "-") // trim repeated dashes
      .toLowerCase();
  }

  const columns = [
    {
      dataField: "place",
      text: "Sıra"
    },
    {
      dataField: "employeeFirmDTO.vendorName",
      text: "Bayi Adı"
    },
    {
      dataField: "employeeFirmDTO.employee.fullName",
      text: "Ad Soyad"
    },
    {
      dataField: "winnerDate",
      text: "Tarih"
    }
  ];

  var table;
  if (winnerList && resetFilter && winnerList.length > 0) {
    table = null; //(
    // <BootstrapTable
    //   bootstrap4
    //   keyField="id"
    //   data={winnerList}
    //   columns={columns}
    //   pagination={paginationFactory()}
    // />
    //);
  } else if (
    searchNameResults &&
    !resetFilter &&
    searchNameResults.length > 0
  ) {
    table = null;
    //(
    // <BootstrapTable
    //   className="table"
    //   bootstrap4
    //   keyField="id"
    //   data={searchNameResults}
    //   columns={columns}
    //   pagination={paginationFactory()}
    // />
    //);
  } else {
    table = <div>Yarışma Kazanan Listesinde Kullanıcı Mevcut Değil</div>;
  }

  return (
    <div
      className="wrapper-winners rounded-2xl"
      style={{
        color:
          disclaimerList && disclaimerList[0] && disclaimerList[0].primaryColor
            ? disclaimerList[0].primaryColor
            : "#FFF"
      }}
    >
      <div className="wrapper-question-item">
        {/* <GlobalLoader isLoading={!loaded} /> */}
        {/* <Moment locale="tr">{dateToFormat}</Moment> */}
        <h1
          style={{
            color:
              disclaimerList &&
              disclaimerList[0] &&
              disclaimerList[0].primaryColor
                ? disclaimerList[0].primaryColor
                : "#FFF"
          }}
        >
          Kazananlar Listesi
        </h1>
        <span>Lütfen bayi adınızı yazarak “Sorgula” tuşuna basınız</span>
        <form action="" className="mt-1 mb-3">
          <div className="row">
            <div className="col-sm mt-2">
              {" "}
              <input
                type="text"
                className="form-control w-100"
                value={searchName}
                onChange={setSearchNames}
                placeholder="Bayi Adınızı Yazınız"
              />
            </div>
            <div className="col-sm mt-2">
              <DatePicker
                selected={date}
                className="form-control w-100"
                onChange={setSearchDates}
                dateFormat="dd-MM-yyyy"
                placeholderText="Tarih Yazınız"
              />
            </div>
            <div className="col-sm mt-2">
              <div className="w-100">
                <button
                  type="submit"
                  onClick={e => {
                    e.preventDefault();
                    search();
                  }}
                  className="btn pl-0 pr-0 w-100"
                  style={{
                    backgroundColor:
                      disclaimerList &&
                      disclaimerList[0] &&
                      disclaimerList[0]?.buttonList[0]?.buttonColor &&
                      disclaimerList[0]?.buttonList[0]?.buttonColor
                  }}
                >
                  SORGULA
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="react-bootstrap-table">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="w-20">
                  Sıra
                </th>
                <th scope="col" className="w-20">
                  Bayi Adı
                </th>
                <th scope="col">Ad Soyad</th>
                <th scope="col" className="w-20">
                  Tarih
                </th>
              </tr>
            </thead>
            <tbody>
              {searchNameResults &&
              !resetFilter &&
              searchNameResults.length > 0 ? (
                searchNameResults.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td scope="row">{item.place}</td>
                      <td>{item.employeeFirmDTO.vendorName}</td>
                      <td>{item.employeeFirmDTO.employee.fullName} </td>
                      <td>{item.winnerDate}</td>
                    </tr>
                  );
                })
              ) : winnerList && resetFilter && winnerList.length > 0 ? (
                winnerList.map(item => {
                  return (
                    <tr key={item.id}>
                      <td scope="row">{item.place}</td>
                      <td>{item.employeeFirmDTO.vendorName}</td>
                      <td>{item.employeeFirmDTO.employee.fullName} </td>
                      <td>{item.winnerDate}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="4">
                    Yarışma Kazanan Listesinde Kullanıcı Mevcut Değil
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <style>
        {`
          .react-bootstrap-table .table.table-bordered th, .react-bootstrap-table .table.table-bordered td {
            color: ${
              disclaimerList &&
              disclaimerList[0] &&
              disclaimerList[0].primaryColor
                ? disclaimerList[0].primaryColor
                : "#FFF"
            };
          }
        `}
      </style>
    </div>
  );
};
export default Winner;
