import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { employeeFirmAction, authAction } from "redux/modules";

const Security = () => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const [checkboxStatus, setCheckboxStatus] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (employeeFirm && employeeFirm.isOpenTwoFactor !== null) {
      setCheckboxStatus(employeeFirm.isOpenTwoFactor);
    } else {
      setCheckboxStatus(false);
    }
  }, []);

  const handleChangeStatus = event => {
    setCheckboxStatus(event.target.checked);
  };

  const handleChangeSecurityStatus = event => {
    event.preventDefault();

    dispatch(
      employeeFirmAction.setSecurityStatus(employeeFirm.id, checkboxStatus)
    ).then(res => {
      dispatch(
        authAction.returnNewScoreOfEmployee(
          employeeFirm.firm.id,
          employeeFirm.employee.id
        )
      );
    });
  };

  return (
    <div
      className="tab-pane fade"
      id="security"
      role="tabpanel"
      aria-labelledby="security-tab"
    >
      <div className="form-container">
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <div className="form-row">
            <div className="security-title">
              Değerli Bayimiz, iki adımlı giriş yapabilmek için, “İki Adımlı
              Giriş” seçeneğini “Aktif” olarak işaretleyebilirsiniz.
              <br />
              <br />
              Seçenek “Aktif” olduğunda, Benim Bayim’e giriş yapabilmek için
              kullanıcı bilgilerinizi girdiğinizde, cep telefonunuza tek
              kullanımlık güvenlik kodu gelecektir.
              <br />
              <br />
              Bu kodu girerek, sisteme giriş yapabilirsiniz.
            </div>

            <div className="form-el">
              <div className={"mb-1"}>Bayi Tabela Adı</div>
              <input
                id="vendorName"
                type="text"
                className="form-control"
                placeholder="Bayi Tabela Adı"
                disabled={true}
                value={employeeFirm.vendorName}
              />
            </div>

            {employeeFirm && (
              <div className="form-el">
                <label>İki Adımlı Giriş</label>
                <div className="form-check">
                  <input
                    type="checkbox"
                    defaultChecked={
                      employeeFirm.isOpenTwoFactor
                        ? employeeFirm.isOpenTwoFactor
                        : checkboxStatus
                    }
                    className="form-check-input"
                    id="securityStatus"
                    onChange={e => handleChangeStatus(e)}
                  />
                  <label className="form-check-label" htmlFor="securityStatus">
                    Aktif
                  </label>
                </div>
              </div>
            )}

            <div className="m-0 mt-3 pl-md-4 pr-md-4 w-100 submit d-block d-md-flex justify-content-end">
              <button
                type="button"
                className="ea-btn bg-green d-block"
                onClick={e => handleChangeSecurityStatus(e)}
              >
                KAYDET
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Security;
