import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const Monthly2 = ({ data }) => {
  const [chartData, setChartData] = useState([{ name: "Gelir", data: [] }]);

  const [options, setOptions] = useState({
    chart: {
      height: 400,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: ["#77B6EA", "#545454"],
    dataLabels: {
      enabled: true
    },
    stroke: {
      curve: "smooth"
    },
    title: {
      text: "Aylık Kazanç Miktarı",
      align: "center"
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5
      }
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: [],
      labels: {
        rotate: -45,
        rotateAlways: true
      }
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  });

  const formatDateString = dateString => {
    return dateString
      .split(" - ")
      .reverse()
      .join(" ");
  };

  useEffect(() => {
    if (data?.data?.length > 0) {
      // Incomes
      const newData = data.data.map(item =>
        typeof item.income === "string"
          ? parseInt(item.income)
          : item.income.toFixed(0)
      );
      // Seasons
      const newMonths = data.data.map(item => formatDateString(item.season));

      // Verilerden ortalama değeri hesapla
      const values = data.data.map(item => parseInt(item.income));

      // Ortalama değerlerin etrafında bir marj belirleyerek min ve max değerleri ayarla
      const margin = 30; // Marj değeri
      const min =
        Math.min(...values) - margin <= 0 ? 0 : Math.min(...values) - margin;
      const max = Math.max(...values) + margin;

      // Yeni değerleri aylara ekler
      setChartData(prevChartData => {
        const updatedChartData = [...prevChartData];
        const lastIndex = updatedChartData.length - 1;
        if (lastIndex >= 0) {
          updatedChartData[lastIndex] = {
            ...updatedChartData[lastIndex],
            data: newData
          };
        }
        return updatedChartData;
      });

      // Yeni min ve max değerleriyle yaxis'i güncelle
      setOptions(prevOptions => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: newMonths
        },
        yaxis: {
          ...prevOptions.yaxis,
          min: min,
          max: max
        }
      }));
    }
  }, [data]);

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={chartData}
          type="bar"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default Monthly2;
