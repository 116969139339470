import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { scoreHuntAction, authAction } from "redux/modules";
import showMessage from "hooks/showMessage";
import showErrMessage from "hooks/showErrMessage";
import { Link, Redirect } from "react-router-dom";
import GlobalLoader from "pages/GlobalLoader/GlobalLoader";

import {
  IoMenuOutline,
  IoCloseOutline,
  IoChevronDown,
  IoArrowBack,
  IoInformationCircle,
  IoArrowForward
} from "react-icons/io5";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import { scroller } from "react-scroll";
import { animateScroll as scroll } from "react-scroll";

const ScoreHuntQuestion = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const scoreHuntList = useSelector(store => store.scoreHunt.scoreHuntList);
  const selectedOption = useSelector(store => store.scoreHunt.selectedOption);
  const isLoading = useSelector(store => store.scoreHunt.isLoading);

  var scoreHuntGame = null;

  const [seconds, setSeconds] = useState();

  const [totalSeconds, setTotalSeconds] = useState(null);
  const [defaultTotalSeconds, setDefaultTotalSeconds] = useState(90);

  const [isActive, setIsActive] = useState(true);

  const [redirect, setRedirect] = useState(false);
  const [redirectNoQuestion, setRedirectNoQuestion] = useState(false);

  const choiceList = [];
  const [selectedChoice, setSelectedChoice] = useState([]);

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return (
        <div className="timer text-[16px] md:text-[24px] font-bold text-white">
          Süre Doldu!
        </div>
      );
    }

    return (
      <div className="timer text-center">
        <div className="value text-[36px] md:text-[48px] md:leading-[58px] leading-[44px] font-bold text-white">
          {remainingTime}
        </div>
        <div className="text text-[12px] font-bold text-white">SANİYE</div>
      </div>
    );
  };

  useEffect(() => {
    dispatch(scoreHuntAction.getScoreHuntByFirmId(employeeFirm.id)).then(e => {
      if (e.data) {
        e.data.map(item => {
          setSeconds(item.remainTime);

          if (item.totalRemainTime) {
            setTotalSeconds(item.totalRemainTime);
          }
        });
      }
    });

    return () => {};
  }, []);

  function stopTimer() {
    setIsActive(false);
  }

  function stopTimerToZero(question, questionChoice, employeeFirmId, firmId) {
    setSeconds(0);
    setIsActive(false);
    questionChoice = null;
    dispatch(
      scoreHuntAction.saveFindQuestionAnswer(
        question,
        questionChoice,
        employeeFirmId
      )
    );
    setRedirect(true);
  }

  function onClickOption(value, choiceId) {
    choiceList.push(choiceId);
    setSelectedChoice(choiceList);
    dispatch(scoreHuntAction.changeOption(value));
  }

  function showChoices(choicesList) {
    var questionChoices = null;

    questionChoices = choicesList.map((itemChoice, index) => (
      <div
        className="flex items-center mb-[16px] md:mb-[24px]"
        key={itemChoice.id}
      >
        {selectedOption === index + 1 ? (
          <input
            id={`radio${index + 1}`}
            type="radio"
            name="radio"
            className="hidden"
            checked
          />
        ) : (
          <input
            id={`radio${index + 1}`}
            type="radio"
            name="radio"
            className="hidden"
          />
        )}

        <label
          htmlFor={`radio${index + 1}`}
          onClick={() => onClickOption(index + 1, itemChoice.id)}
          className="flex items-center cursor-pointer md:text-[16px] text-[14px] font-medium"
        >
          <span className="w-6 h-6 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
          {itemChoice.name}
        </label>
      </div>
    ));
    return questionChoices;
  }

  function saveQuestion(question, questionChoice, employeeFirmId, firmId, e) {
    if (questionChoice.length <= 0) {
      showErrMessage("Seçenek seçiniz.");
      e.preventDefault();
    } else {
      stopTimer();
      dispatch(
        scoreHuntAction.saveFindQuestionAnswer(
          question,
          questionChoice,
          employeeFirmId
        )
      )
        .then(e => {
          dispatch(
            authAction.returnNewScoreOfEmployee(
              employeeFirm.firm.id,
              employeeFirm.employee.id
            )
          );
          setRedirect(true);
        })
        .catch(err => {
          showErrMessage("Seçenek seçiniz.");
        });
    }
  }

  if (redirect) {
    return (
      <Redirect
        from="/userportal/scoreHuntQuestion"
        to="/userportal/scoreHuntAnswer"
      />
    );
  }

  function noQuestion() {
    setRedirectNoQuestion(true);
  }

  if (redirectNoQuestion) {
    return (
      <Redirect
        from="/userportal/scoreHuntQuestion"
        to="/userportal/scoreHunt"
      />
    );
  }

  if (scoreHuntList) {
    scoreHuntGame = scoreHuntList.map(item => {
      return (
        <form
          className="w-100"
          key={item.id}
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <input type="hidden" name="cevap" value={selectedOption} />
          <div className="container mx-auto py-20 px-6 md:px-0">
            <div className="flex gap-4 h-100 flex-col md:flex-row">
              <div className="relative h-[333px] w-[308px] gap-20 bg-[url('assets/images/new-design/game/card-bg-blue.png')] bg-left-bottom bg-no-repeat mx-auto">
                <h2 className="md:text-[36px] text-[28px] text-center px-4 py-4 font-bold text-white">
                  Kalan Süre
                </h2>

                <div className="countdown flex justify-center">
                  {seconds > 0 && (
                    <CountdownCircleTimer
                      isPlaying
                      size={156}
                      strokeLinecap="square"
                      duration={
                        totalSeconds ? totalSeconds : defaultTotalSeconds
                      }
                      initialRemainingTime={seconds}
                      colors="#FFFFFF"
                      onComplete={() => {
                        stopTimerToZero(
                          item.questionId,
                          selectedChoice,
                          employeeFirm.id,
                          employeeFirm.firm.id
                        );

                        return { shouldRepeat: false, delay: 1 }; // repeat animation in 1 seconds
                      }}
                    >
                      {renderTime}
                    </CountdownCircleTimer>
                  )}
                </div>
              </div>
              <div className="bg-white flex-1 flex justify-center items-start rounded-[12px] lg:flex-row flex-col md:py-12 py-8 md:px-12 px-8">
                <div className="left flex flex-col items-start lg:w-[40%] w-[100%]">
                  <div className="question-count bg-[#38c086] rounded-[17px] px-[16px] py-[8px]">
                    <p className="text-[12px] text-center font-bold text-white">
                      Soru
                    </p>
                  </div>
                  <div className="questions mt-[12px]">
                    <p className="md:text-[24px] text-[18px] font-bold text-[#64748b]">
                      {item.description && item.description}
                    </p>
                  </div>
                </div>
                <div className="right lg:w-[60%] w-[100%] lg:pl-[12%] pl-[0] lg:pt-[0] pt-[10%]">
                  {showChoices(item.questionChoices)}
                </div>
              </div>
            </div>
            <div className="w-full md:w-[calc(100%-324px)] bg-white flex-1 flex justify-between ml-auto items-start rounded-[12px] flex-col mt-4 py-6 md:px-8 px-4">
              <div className="action flex justify-between w-full">
                <Link
                  to={`/userportal/scoreHunt`}
                  className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[152px] min-w-[130px] max-h-[52px] rounded-full border-2 border-[#16435e] bg-white text-[#16435e] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px]"
                >
                  <IoArrowBack className="h-5 w-5" />
                  Geri Git{" "}
                </Link>
                <button
                  type="button"
                  className="font-medium flex items-center justify-between md:text-[16px] text-[14px] gap-2 md:min-w-[135px] min-w-[120px] max-h-[52px] rounded-full border-2 bg-[#00b185] border-[#00b185] !py-[16px] !pl-[24px] !pr-[24px] !md:pr-[36px] text-white"
                  onClick={e =>
                    saveQuestion(
                      item.questionId,
                      selectedChoice,
                      employeeFirm.id,
                      employeeFirm.firm.id,
                      e
                    )
                  }
                >
                  Cevapla <IoArrowForward className="h-5 w-5 text-white" />
                </button>
              </div>
            </div>
          </div>
        </form>
      );
    });
  }

  return (
    <section className="bg-[#f8fafc] w-100">
      <GlobalLoader isLoading={isLoading} />
      {scoreHuntGame}
    </section>
  );
};
export default ScoreHuntQuestion;
